export const RANGE_DATES = [1789, 2018]
export const MENU_BAR_HEIGHT = '10vh'
export const PLAYER_STRIPE_HEIGHT = 70
export const ZINDEX_MENU_AND_HEADER = 4

export enum MAP_SIZES_STATUS {
  CLOSE,
  SMALL,
  LARGE,
}
export const MAP_WIDTHS: {
  [key in MAP_SIZES_STATUS]: number
} = {
  [MAP_SIZES_STATUS.CLOSE]: 0,
  [MAP_SIZES_STATUS.SMALL]: 25,
  [MAP_SIZES_STATUS.LARGE]: 70,
}
export const ENTITIES_KEY = {
  TRACK: 'track',
  STYLE: 'style',
  PERSON: 'person',
  GROUP: 'group',
  TAG: 'tag',
  INSTRUMENT: 'instrument',
  USER: 'user',
}

interface IENTITIES_LABEL {
  POSTLIST: string
  TRACK: string
  STYLE: string
  PERSON: string
  GROUP: string
  TAG: string
  INSTRUMENT: string
  USER: string
  [key: string]: string
}

export const ENTITIES_LABEL: IENTITIES_LABEL = {
  POSTLIST: 'Parcours',
  TRACK: 'Morceau',
  STYLE: 'Style',
  PERSON: 'Artiste',
  GROUP: 'Groupe',
  TAG: 'Thème',
  INSTRUMENT: 'Instrument',
  USER: 'Utilisateur',
  COLLECTION: 'Collection',
}

export const ENTITIES_PLURAL_LABEL: IENTITIES_LABEL = {
  POSTLIST: 'Parcours',
  TRACK: 'Morceaux',
  STYLE: 'Styles',
  PERSON: 'Artistes',
  GROUP: 'Groupes',
  TAG: 'Thèmes',
  INSTRUMENT: 'Instruments',
  USER: 'Utilisateurs',
  COLLECTION: 'Collections',
}

interface IPOSTS_LABEL {
  standard: string
  lyrics: string
  video: string
  audio: string
  media: string
  link_to_entity: string
  image: string
  quote: string
  score: string
  external_link: string
  title: string
  [key: string]: string
}

export const POSTS_LABEL: IPOSTS_LABEL = {
  standard: 'Texte',
  lyrics: 'Paroles',
  video: 'Video',
  audio: 'Audio',
  media: 'Media externe (youtube, soundcloud, etc...)',
  link_to_entity: 'Lien vers une notice',
  image: 'Image',
  quote: 'Citation',
  score: 'Partition',
  external_link: 'Lien externe',
  title: 'Chapitre',
}
