import { combineReducers, Action } from 'redux'
import search from '~/ducks/search'
import player from '~/ducks/player'
import map from '~/ducks/map'
import auth from '~/ducks/auth'
import routes from '~/ducks/routes'
import toast from '~/ducks/toast'
import dialog from '~/ducks/dialog'
import header from '~/ducks/header'
import editor from '~/ducks/editor'
import { reducer as formReducer } from 'redux-form'
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from 'react-redux'
import { RootState } from 'Types'
import { ThunkDispatch, ThunkAction as ThunkReduxAction } from 'redux-thunk'

const rootReducer = combineReducers({
  map,
  search,
  player,
  auth,
  toast,
  header,
  dialog,
  routes,
  editor,
  form: formReducer,
})

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export type ReduxDispatch = ThunkDispatch<RootState, any, Action>
export function useDispatch(): ReduxDispatch {
  return useReduxDispatch<ReduxDispatch>()
}
export type ThunkAction<R> = ThunkReduxAction<
  R,
  RootState,
  undefined,
  Action<string>
>

export default rootReducer
