import nextI18next, {
  i18n,
  Router,
  Link,
  useTranslation,
  appWithTranslation,
  Trans,
} from '../i18n'

export default nextI18next
export { i18n, Router, Link, useTranslation, appWithTranslation, Trans }
