/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventPostForEditor
// ====================================================

export interface getEventPostForEditor_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

export interface getEventPostForEditor {
  /**
   * The ID of the object
   */
  post: getEventPostForEditor_post | null
}

export interface getEventPostForEditorVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCollectionPlayerMeta
// ====================================================

export interface getCollectionPlayerMeta_collection {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getCollectionPlayerMeta {
  /**
   * The ID of the object
   */
  collection: getCollectionPlayerMeta_collection | null
}

export interface getCollectionPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonPlayerMeta
// ====================================================

export interface getPersonPlayerMeta_person_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getPersonPlayerMeta_person_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getPersonPlayerMeta_person_artwork_renditions_thumbnail | null
}

export interface getPersonPlayerMeta_person_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPersonPlayerMeta_person_artwork_renditions | null
}

export interface getPersonPlayerMeta_person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getPersonPlayerMeta_person_artwork | null
}

export interface getPersonPlayerMeta {
  person: getPersonPlayerMeta_person | null
}

export interface getPersonPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupPlayerMeta
// ====================================================

export interface getGroupPlayerMeta_group_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getGroupPlayerMeta_group_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getGroupPlayerMeta_group_artwork_renditions_thumbnail | null
}

export interface getGroupPlayerMeta_group_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getGroupPlayerMeta_group_artwork_renditions | null
}

export interface getGroupPlayerMeta_group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getGroupPlayerMeta_group_artwork | null
}

export interface getGroupPlayerMeta {
  group: getGroupPlayerMeta_group | null
}

export interface getGroupPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getStylePlayerMeta
// ====================================================

export interface getStylePlayerMeta_style_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getStylePlayerMeta_style_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getStylePlayerMeta_style_artwork_renditions_thumbnail | null
}

export interface getStylePlayerMeta_style_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getStylePlayerMeta_style_artwork_renditions | null
}

export interface getStylePlayerMeta_style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getStylePlayerMeta_style_artwork | null
}

export interface getStylePlayerMeta {
  style: getStylePlayerMeta_style | null
}

export interface getStylePlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTrackPlayerMeta
// ====================================================

export interface getTrackPlayerMeta_track_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getTrackPlayerMeta_track_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getTrackPlayerMeta_track_artwork_renditions_thumbnail | null
}

export interface getTrackPlayerMeta_track_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getTrackPlayerMeta_track_artwork_renditions | null
}

export interface getTrackPlayerMeta_track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getTrackPlayerMeta_track_artwork | null
}

export interface getTrackPlayerMeta {
  track: getTrackPlayerMeta_track | null
}

export interface getTrackPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getInstrumentPlayerMeta
// ====================================================

export interface getInstrumentPlayerMeta_instrument_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getInstrumentPlayerMeta_instrument_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getInstrumentPlayerMeta_instrument_artwork_renditions_thumbnail | null
}

export interface getInstrumentPlayerMeta_instrument_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getInstrumentPlayerMeta_instrument_artwork_renditions | null
}

export interface getInstrumentPlayerMeta_instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getInstrumentPlayerMeta_instrument_artwork | null
}

export interface getInstrumentPlayerMeta {
  instrument: getInstrumentPlayerMeta_instrument | null
}

export interface getInstrumentPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTagPlayerMeta
// ====================================================

export interface getTagPlayerMeta_tag_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getTagPlayerMeta_tag_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getTagPlayerMeta_tag_artwork_renditions_thumbnail | null
}

export interface getTagPlayerMeta_tag_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getTagPlayerMeta_tag_artwork_renditions | null
}

export interface getTagPlayerMeta_tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getTagPlayerMeta_tag_artwork | null
}

export interface getTagPlayerMeta {
  tag: getTagPlayerMeta_tag | null
}

export interface getTagPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListPlayerMeta
// ====================================================

export interface getPostListPlayerMeta_postList_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getPostListPlayerMeta_postList_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getPostListPlayerMeta_postList_artwork_renditions_thumbnail | null
}

export interface getPostListPlayerMeta_postList_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListPlayerMeta_postList_artwork_renditions | null
}

export interface getPostListPlayerMeta_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  artwork: getPostListPlayerMeta_postList_artwork | null
}

export interface getPostListPlayerMeta {
  postList: getPostListPlayerMeta_postList | null
}

export interface getPostListPlayerMetaVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMetadataForAddMediaPost
// ====================================================

export interface getMetadataForAddMediaPost_mediaMetadata {
  __typename: 'MediaMetadata'
  name: string | null
  url: string | null
  duration: number | null
  html: string | null
  author: string | null
  provider: string | null
  description: string | null
  thumbnail: string | null
  thumbnailWidth: number | null
  thumbnailHeight: number | null
}

export interface getMetadataForAddMediaPost {
  mediaMetadata: getMetadataForAddMediaPost_mediaMetadata | null
}

export interface getMetadataForAddMediaPostVariables {
  url: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAPost
// ====================================================

export interface createAPost_createPost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createAPost_createPost {
  __typename: 'CreatePostMutationPayload'
  /**
   * Le media doit être compatible avec le player Mélo (Youtube, Soundcloud, dailymotion...)
   */
  mediaUrl: string | null
  id: number | null
  body: string | null
  name: string | null
  hasVisualContent: boolean | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createAPost_createPost_errors | null)[] | null
}

export interface createAPost {
  createPost: createAPost_createPost | null
}

export interface createAPostVariables {
  body?: string | null
  name: string
  authorFreetext?: string | null
  hasVisualContent?: boolean | null
  entity: string
  mediaUrl: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchForPlaylist
// ====================================================

export interface searchForPlaylist_search_results {
  __typename: 'SearchResult'
  id: string
  slug: string | null
  postType: string | null
  type: string
  name: string | null
  duration: number | null
  thumbnail: string | null
  lng: any | null
}

export interface searchForPlaylist_search {
  __typename: 'SearchResponse'
  hasMore: boolean
  count: number
  results: (searchForPlaylist_search_results | null)[]
}

export interface searchForPlaylist {
  search: searchForPlaylist_search | null
}

export interface searchForPlaylistVariables {
  term: string
  offset: number
  ordering?: string | null
  limit: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCardHeader
// ====================================================

export interface getCardHeader_entity_PostList {
  __typename: 'PostList'
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Track_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Track_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Track_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Track_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Track_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Track_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Track_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Track_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Track_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Track_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Track_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Track_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Track_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Track_artwork_renditions | null
}

export interface getCardHeader_entity_Track_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Track_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Track_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Track_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Track_posts_edges_node | null
}

export interface getCardHeader_entity_Track_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Track_posts_edges | null)[]
}

export interface getCardHeader_entity_Track {
  __typename: 'Track'
  date: any | null
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Track_collectionSet | null
  artwork: getCardHeader_entity_Track_artwork | null
  posts: getCardHeader_entity_Track_posts | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Style_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Style_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Style_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Style_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Style_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Style_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Style_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Style_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Style_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Style_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Style_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Style_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Style_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Style_artwork_renditions | null
}

export interface getCardHeader_entity_Style_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Style_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Style_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Style_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Style_posts_edges_node | null
}

export interface getCardHeader_entity_Style_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Style_posts_edges | null)[]
}

export interface getCardHeader_entity_Style {
  __typename: 'Style'
  styleDate: any | null
  endDate: any | null
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Style_collectionSet | null
  artwork: getCardHeader_entity_Style_artwork | null
  posts: getCardHeader_entity_Style_posts | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Person_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Person_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Person_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Person_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Person_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Person_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Person_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Person_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Person_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Person_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Person_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Person_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Person_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Person_artwork_renditions | null
}

export interface getCardHeader_entity_Person_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Person_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Person_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Person_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Person_posts_edges_node | null
}

export interface getCardHeader_entity_Person_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Person_posts_edges | null)[]
}

export interface getCardHeader_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Person_collectionSet | null
  artwork: getCardHeader_entity_Person_artwork | null
  posts: getCardHeader_entity_Person_posts | null
  birthDate: any | null
  deathDate: any | null
  locationName: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Group_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Group_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Group_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Group_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Group_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Group_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Group_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Group_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Group_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Group_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Group_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Group_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Group_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Group_artwork_renditions | null
}

export interface getCardHeader_entity_Group_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Group_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Group_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Group_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Group_posts_edges_node | null
}

export interface getCardHeader_entity_Group_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Group_posts_edges | null)[]
}

export interface getCardHeader_entity_Group {
  __typename: 'Group'
  startDate: any | null
  endDate: any | null
  locationName: string | null
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Group_collectionSet | null
  artwork: getCardHeader_entity_Group_artwork | null
  posts: getCardHeader_entity_Group_posts | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Tag_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Tag_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Tag_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Tag_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Tag_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Tag_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Tag_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Tag_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Tag_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Tag_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Tag_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Tag_artwork_renditions | null
}

export interface getCardHeader_entity_Tag_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Tag_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Tag_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Tag_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Tag_posts_edges_node | null
}

export interface getCardHeader_entity_Tag_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Tag_posts_edges | null)[]
}

export interface getCardHeader_entity_Tag {
  __typename: 'Tag'
  startDate: any | null
  endDate: any | null
  description: string | null
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Tag_collectionSet | null
  artwork: getCardHeader_entity_Tag_artwork | null
  posts: getCardHeader_entity_Tag_posts | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node =
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Person
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Group
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Tag
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Instrument
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Track
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_Style
  | getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node_PostList

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges_node | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Instrument_collectionSet_edges_node_entities_edges | null)[]
}

export interface getCardHeader_entity_Instrument_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: getCardHeader_entity_Instrument_collectionSet_edges_node_entities | null
}

export interface getCardHeader_entity_Instrument_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Instrument_collectionSet_edges_node | null
}

export interface getCardHeader_entity_Instrument_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Instrument_collectionSet_edges | null)[]
}

export interface getCardHeader_entity_Instrument_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCardHeader_entity_Instrument_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getCardHeader_entity_Instrument_artwork_renditions_thumbnail | null
}

export interface getCardHeader_entity_Instrument_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCardHeader_entity_Instrument_artwork_renditions | null
}

export interface getCardHeader_entity_Instrument_posts_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface getCardHeader_entity_Instrument_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: getCardHeader_entity_Instrument_posts_edges_node_createdBy | null
}

export interface getCardHeader_entity_Instrument_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCardHeader_entity_Instrument_posts_edges_node | null
}

export interface getCardHeader_entity_Instrument_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCardHeader_entity_Instrument_posts_edges | null)[]
}

export interface getCardHeader_entity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  type: BaseNodeTypes
  collectionSet: getCardHeader_entity_Instrument_collectionSet | null
  artwork: getCardHeader_entity_Instrument_artwork | null
  posts: getCardHeader_entity_Instrument_posts | null
}

export type getCardHeader_entity =
  | getCardHeader_entity_PostList
  | getCardHeader_entity_Track
  | getCardHeader_entity_Style
  | getCardHeader_entity_Person
  | getCardHeader_entity_Group
  | getCardHeader_entity_Tag
  | getCardHeader_entity_Instrument

export interface getCardHeader {
  entity: getCardHeader_entity | null
}

export interface getCardHeaderVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCollectionEntities
// ====================================================

export interface getCollectionEntities_collection_entities_pageInfo {
  __typename: 'PageInfo'
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  birthDate: any | null
  deathDate: any | null
  birthPlace: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  birthPlace: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  description: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
}

export interface getCollectionEntities_collection_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getCollectionEntities_collection_entities_edges_node_Track_authors_edges_node | null
}

export interface getCollectionEntities_collection_entities_edges_node_Track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCollectionEntities_collection_entities_edges_node_Track_authors_edges | null)[]
}

export interface getCollectionEntities_collection_entities_edges_node_Track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getCollectionEntities_collection_entities_edges_node_Track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getCollectionEntities_collection_entities_edges_node_Track_bands_edges_node | null
}

export interface getCollectionEntities_collection_entities_edges_node_Track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCollectionEntities_collection_entities_edges_node_Track_bands_edges | null)[]
}

export interface getCollectionEntities_collection_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  date: any | null
  authors: getCollectionEntities_collection_entities_edges_node_Track_authors
  bands: getCollectionEntities_collection_entities_edges_node_Track_bands
}

export interface getCollectionEntities_collection_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  locationName: string | null
  endDate: any | null
}

export interface getCollectionEntities_collection_entities_edges_node_PostList_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  displayName: string
}

export interface getCollectionEntities_collection_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  /**
   * Displayed in the article teaser
   */
  description: string | null
  createdBy: getCollectionEntities_collection_entities_edges_node_PostList_createdBy | null
}

export type getCollectionEntities_collection_entities_edges_node =
  | getCollectionEntities_collection_entities_edges_node_Person
  | getCollectionEntities_collection_entities_edges_node_Group
  | getCollectionEntities_collection_entities_edges_node_Tag
  | getCollectionEntities_collection_entities_edges_node_Instrument
  | getCollectionEntities_collection_entities_edges_node_Track
  | getCollectionEntities_collection_entities_edges_node_Style
  | getCollectionEntities_collection_entities_edges_node_PostList

export interface getCollectionEntities_collection_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getCollectionEntities_collection_entities_edges_node | null
}

export interface getCollectionEntities_collection_entities {
  __typename: 'EntityConnection'
  /**
   * Pagination data for this connection.
   */
  pageInfo: getCollectionEntities_collection_entities_pageInfo
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCollectionEntities_collection_entities_edges | null)[]
}

export interface getCollectionEntities_collection {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  entities: getCollectionEntities_collection_entities | null
}

export interface getCollectionEntities {
  /**
   * The ID of the object
   */
  collection: getCollectionEntities_collection | null
}

export interface getCollectionEntitiesVariables {
  collectionId: string
  entityTypes?: (EntityType | null)[] | null
  entityCursor?: string | null
  seed?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCollectionEvents
// ====================================================

export interface getCollectionEvents_collection_events_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  thumbnail: string | null
  locationName: string | null
  authorFreetext: string | null
}

export interface getCollectionEvents_collection_events_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getCollectionEvents_collection_events_edges_node | null
}

export interface getCollectionEvents_collection_events {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getCollectionEvents_collection_events_edges | null)[]
}

export interface getCollectionEvents_collection {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  events: getCollectionEvents_collection_events | null
}

export interface getCollectionEvents {
  /**
   * The ID of the object
   */
  collection: getCollectionEvents_collection | null
}

export interface getCollectionEventsVariables {
  collectionId: string
  past?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCollection
// ====================================================

export interface getCollection_collection_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getCollection_collection_background_renditions {
  __typename: 'Renditions'
  big: getCollection_collection_background_renditions_big | null
}

export interface getCollection_collection_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getCollection_collection_background_renditions | null
}

export interface getCollection_collection_createdBy {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  displayName: string
  avatar: string | null
}

export interface getCollection_collection {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  subtitle: string | null
  body: string | null
  background: getCollection_collection_background | null
  createdBy: getCollection_collection_createdBy | null
}

export interface getCollection {
  /**
   * The ID of the object
   */
  collection: getCollection_collection | null
}

export interface getCollectionVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCurrentUserAvatar
// ====================================================

export interface updateCurrentUserAvatar_updateCurrentUser_payload {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  avatar: string | null
}

export interface updateCurrentUserAvatar_updateCurrentUser {
  __typename: 'UpdateCurrentUserMutationPayload'
  payload: updateCurrentUserAvatar_updateCurrentUser_payload | null
}

export interface updateCurrentUserAvatar {
  updateCurrentUser: updateCurrentUserAvatar_updateCurrentUser | null
}

export interface updateCurrentUserAvatarVariables {
  originalAvatar: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deactivateCurrentUser
// ====================================================

export interface deactivateCurrentUser_deactivateCurrentUser {
  __typename: 'DeactivateCurrentUserMutationPayload'
  deleted: boolean | null
}

export interface deactivateCurrentUser {
  deactivateCurrentUser: deactivateCurrentUser_deactivateCurrentUser | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCurrentUser
// ====================================================

export interface updateCurrentUser_updateCurrentUser_payload {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  shortName: string
  bio: string | null
}

export interface updateCurrentUser_updateCurrentUser {
  __typename: 'UpdateCurrentUserMutationPayload'
  payload: updateCurrentUser_updateCurrentUser_payload | null
}

export interface updateCurrentUser {
  updateCurrentUser: updateCurrentUser_updateCurrentUser | null
}

export interface updateCurrentUserVariables {
  shortName: string
  bio: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: editCurrentUserProfil
// ====================================================

export interface editCurrentUserProfil_currentUser {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  shortName: string
  slug: string
  email: string
  bio: string | null
  avatar: string | null
}

export interface editCurrentUserProfil {
  currentUser: editCurrentUserProfil_currentUser | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventForCollectionEventDetail
// ====================================================

export interface eventForCollectionEventDetail_post_entities_edges_node_Instrument {
  __typename: 'Instrument' | 'Style' | 'Track' | 'Tag' | 'PostList'
}

export interface eventForCollectionEventDetail_post_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
}

export interface eventForCollectionEventDetail_post_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
}

export type eventForCollectionEventDetail_post_entities_edges_node =
  | eventForCollectionEventDetail_post_entities_edges_node_Instrument
  | eventForCollectionEventDetail_post_entities_edges_node_Person
  | eventForCollectionEventDetail_post_entities_edges_node_Group

export interface eventForCollectionEventDetail_post_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: eventForCollectionEventDetail_post_entities_edges_node | null
}

export interface eventForCollectionEventDetail_post_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (eventForCollectionEventDetail_post_entities_edges | null)[]
}

export interface eventForCollectionEventDetail_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  body: string | null
  thumbnail: string | null
  locationName: string | null
  authorFreetext: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  entities: eventForCollectionEventDetail_post_entities | null
}

export interface eventForCollectionEventDetail {
  /**
   * The ID of the object
   */
  post: eventForCollectionEventDetail_post | null
}

export interface eventForCollectionEventDetailVariables {
  eventId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: isInUserFavorites
// ====================================================

export interface isInUserFavorites {
  /**
   * If the given object is favorited by the current user
   */
  isInUserFavorites: boolean | null
}

export interface isInUserFavoritesVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: favorite
// ====================================================

export interface favorite_favorite {
  __typename: 'FavoriteMutationPayload'
  deleted: boolean | null
  created: boolean | null
  clientMutationId: string | null
}

export interface favorite {
  favorite: favorite_favorite | null
}

export interface favoriteVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostEventsForImageGenerator
// ====================================================

export interface getPostEventsForImageGenerator_tag_posts_edges_node_artwork_renditions_medium {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_artwork_renditions {
  __typename: 'Renditions'
  medium: getPostEventsForImageGenerator_tag_posts_edges_node_artwork_renditions_medium | null
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_artwork {
  __typename: 'Original'
  renditions: getPostEventsForImageGenerator_tag_posts_edges_node_artwork_renditions | null
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument' | 'Style' | 'Track' | 'Tag' | 'PostList'
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export type getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node =
  | getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Instrument
  | getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Person
  | getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node_Group

export interface getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges_node | null
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostEventsForImageGenerator_tag_posts_edges_node_entities_edges | null)[]
}

export interface getPostEventsForImageGenerator_tag_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  locationName: string | null
  artwork: getPostEventsForImageGenerator_tag_posts_edges_node_artwork | null
  entities: getPostEventsForImageGenerator_tag_posts_edges_node_entities | null
}

export interface getPostEventsForImageGenerator_tag_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostEventsForImageGenerator_tag_posts_edges_node | null
}

export interface getPostEventsForImageGenerator_tag_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostEventsForImageGenerator_tag_posts_edges | null)[]
}

export interface getPostEventsForImageGenerator_tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  posts: getPostEventsForImageGenerator_tag_posts | null
}

export interface getPostEventsForImageGenerator {
  tag: getPostEventsForImageGenerator_tag | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMetadataForExternalUrl
// ====================================================

export interface getMetadataForExternalUrl_mediaMetadata {
  __typename: 'MediaMetadata'
  name: string | null
  url: string | null
  author: string | null
  provider: string | null
  thumbnail: string | null
  error: string | null
}

export interface getMetadataForExternalUrl {
  mediaMetadata: getMetadataForExternalUrl_mediaMetadata | null
}

export interface getMetadataForExternalUrlVariables {
  url: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAnExternalLinkPost
// ====================================================

export interface createAnExternalLinkPost_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface createAnExternalLinkPost_mutatePost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createAnExternalLinkPost_mutatePost {
  __typename: 'PostMutationPayload'
  payload: createAnExternalLinkPost_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createAnExternalLinkPost_mutatePost_errors | null)[] | null
}

export interface createAnExternalLinkPost {
  mutatePost: createAnExternalLinkPost_mutatePost | null
}

export interface createAnExternalLinkPostVariables {
  entity: string
  name: string
  externalUrl: string
  mediaFile?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEventPost
// ====================================================

export interface deleteEventPost_removePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface deleteEventPost_removePost {
  __typename: 'RemovePostMutationPayload'
  errors: string | null
  payload: deleteEventPost_removePost_payload | null
}

export interface deleteEventPost {
  removePost: deleteEventPost_removePost | null
}

export interface deleteEventPostVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMediaForEntityFdlm
// ====================================================

export interface getMediaForEntityFdlm_entity_Group_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Group_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Group_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Group_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Group_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Group_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Group_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Group_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_Person_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Person_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Person_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Person_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Person_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Person_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Person_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Person_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_PostList_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_PostList_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_PostList_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_PostList_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_PostList_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_PostList_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_PostList_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_PostList_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_Tag_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Tag_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Tag_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Tag_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Tag_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Tag_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Tag_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Tag_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_Style_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Style_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Style_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Style_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Style_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Style_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Style_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Style_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Instrument_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Instrument_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Instrument_postsWithRel | null
}

export interface getMediaForEntityFdlm_entity_Track_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface getMediaForEntityFdlm_entity_Track_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getMediaForEntityFdlm_entity_Track_postsWithRel_edges_node_post
}

export interface getMediaForEntityFdlm_entity_Track_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getMediaForEntityFdlm_entity_Track_postsWithRel_edges_node | null
}

export interface getMediaForEntityFdlm_entity_Track_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getMediaForEntityFdlm_entity_Track_postsWithRel_edges | null)[]
}

export interface getMediaForEntityFdlm_entity_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getMediaForEntityFdlm_entity_Track_postsWithRel | null
}

export type getMediaForEntityFdlm_entity =
  | getMediaForEntityFdlm_entity_Group
  | getMediaForEntityFdlm_entity_Person
  | getMediaForEntityFdlm_entity_PostList
  | getMediaForEntityFdlm_entity_Tag
  | getMediaForEntityFdlm_entity_Style
  | getMediaForEntityFdlm_entity_Instrument
  | getMediaForEntityFdlm_entity_Track

export interface getMediaForEntityFdlm {
  entity: getMediaForEntityFdlm_entity | null
}

export interface getMediaForEntityFdlmVariables {
  entityId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeMediaForGroupForFdlm
// ====================================================

export interface removeMediaForGroupForFdlm_removePostEntityRelationship {
  __typename: 'RemovePostEntityRelationshipPayload'
  errors: string | null
}

export interface removeMediaForGroupForFdlm {
  removePostEntityRelationship: removeMediaForGroupForFdlm_removePostEntityRelationship | null
}

export interface removeMediaForGroupForFdlmVariables {
  relId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getExternalUrlsFdlm
// ====================================================

export interface getExternalUrlsFdlm_entity_Group_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Group_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Group_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Group_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Group_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Group_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Group_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Group_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_Person_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Person_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Person_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Person_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Person_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Person_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Person_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Person_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_PostList_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_PostList_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_PostList_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_PostList_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_PostList_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_PostList_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_PostList_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_PostList_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_Tag_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Tag_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Tag_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Tag_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Tag_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Tag_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Tag_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Tag_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_Style_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Style_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Style_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Style_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Style_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Style_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Style_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Style_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Instrument_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Instrument_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Instrument_postsWithRel | null
}

export interface getExternalUrlsFdlm_entity_Track_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface getExternalUrlsFdlm_entity_Track_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: getExternalUrlsFdlm_entity_Track_postsWithRel_edges_node_post
}

export interface getExternalUrlsFdlm_entity_Track_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getExternalUrlsFdlm_entity_Track_postsWithRel_edges_node | null
}

export interface getExternalUrlsFdlm_entity_Track_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getExternalUrlsFdlm_entity_Track_postsWithRel_edges | null)[]
}

export interface getExternalUrlsFdlm_entity_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getExternalUrlsFdlm_entity_Track_postsWithRel | null
}

export type getExternalUrlsFdlm_entity =
  | getExternalUrlsFdlm_entity_Group
  | getExternalUrlsFdlm_entity_Person
  | getExternalUrlsFdlm_entity_PostList
  | getExternalUrlsFdlm_entity_Tag
  | getExternalUrlsFdlm_entity_Style
  | getExternalUrlsFdlm_entity_Instrument
  | getExternalUrlsFdlm_entity_Track

export interface getExternalUrlsFdlm {
  entity: getExternalUrlsFdlm_entity | null
}

export interface getExternalUrlsFdlmVariables {
  entityId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeExternalUrlForEntityForFdlm
// ====================================================

export interface removeExternalUrlForEntityForFdlm_removePostEntityRelationship {
  __typename: 'RemovePostEntityRelationshipPayload'
  errors: string | null
}

export interface removeExternalUrlForEntityForFdlm {
  removePostEntityRelationship: removeExternalUrlForEntityForFdlm_removePostEntityRelationship | null
}

export interface removeExternalUrlForEntityForFdlmVariables {
  relId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePostForEntityFdlm
// ====================================================

export interface updatePostForEntityFdlm_mutatePost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface updatePostForEntityFdlm_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface updatePostForEntityFdlm_mutatePost {
  __typename: 'PostMutationPayload'
  /**
   * May contain more than one error for same field.
   */
  errors: (updatePostForEntityFdlm_mutatePost_errors | null)[] | null
  payload: updatePostForEntityFdlm_mutatePost_payload | null
}

export interface updatePostForEntityFdlm {
  mutatePost: updatePostForEntityFdlm_mutatePost | null
}

export interface updatePostForEntityFdlmVariables {
  id: string
  nameFr?: string | null
  nameEn?: string | null
  nameEs?: string | null
  bodyFr?: string | null
  bodyEn?: string | null
  bodyEs?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEventPost
// ====================================================

export interface createEventPost_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

export interface createEventPost_mutatePost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createEventPost_mutatePost {
  __typename: 'PostMutationPayload'
  payload: createEventPost_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createEventPost_mutatePost_errors | null)[] | null
}

export interface createEventPost {
  mutatePost: createEventPost_mutatePost | null
}

export interface createEventPostVariables {
  id: string
  nameFr?: string | null
  nameEn?: string | null
  nameEs?: string | null
  bodyFr?: string | null
  bodyEn?: string | null
  bodyEs?: string | null
  timezone?: string | null
  startDate?: any | null
  startTime?: any | null
  endDate?: any | null
  endTime?: any | null
  locationName?: string | null
  externalUrl?: string | null
  authorFreetext?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupForFdlm
// ====================================================

export interface getGroupForFdlm_group_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface getGroupForFdlm_group_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: getGroupForFdlm_group_postsWithRel_edges_node_post
}

export interface getGroupForFdlm_group_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getGroupForFdlm_group_postsWithRel_edges_node | null
}

export interface getGroupForFdlm_group_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getGroupForFdlm_group_postsWithRel_edges | null)[]
}

export interface getGroupForFdlm_group_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getGroupForFdlm_group_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getGroupForFdlm_group_artwork_renditions_thumbnail | null
}

export interface getGroupForFdlm_group_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getGroupForFdlm_group_artwork_renditions | null
}

export interface getGroupForFdlm_group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  startDate: any | null
  birthPlace: string | null
  postsWithRel: getGroupForFdlm_group_postsWithRel | null
  artwork: getGroupForFdlm_group_artwork | null
}

export interface getGroupForFdlm {
  group: getGroupForFdlm_group | null
}

export interface getGroupForFdlmVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutateGroupEntityFdlm
// ====================================================

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges_node_post
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges_node | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel_edges | null)[]
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork_renditions_thumbnail | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork_renditions | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_payload {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  startDate: any | null
  birthPlace: string | null
  postsWithRel: mutateGroupEntityFdlm_mutateGroupEntity_payload_postsWithRel | null
  artwork: mutateGroupEntityFdlm_mutateGroupEntity_payload_artwork | null
}

export interface mutateGroupEntityFdlm_mutateGroupEntity_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface mutateGroupEntityFdlm_mutateGroupEntity {
  __typename: 'GroupEntityPayload'
  payload: mutateGroupEntityFdlm_mutateGroupEntity_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (mutateGroupEntityFdlm_mutateGroupEntity_errors | null)[] | null
}

export interface mutateGroupEntityFdlm {
  mutateGroupEntity: mutateGroupEntityFdlm_mutateGroupEntity | null
}

export interface mutateGroupEntityFdlmVariables {
  id: string
  name?: string | null
  startDate?: any | null
  birthPlace?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGroupEntityFdlm
// ====================================================

export interface deleteGroupEntityFdlm_removeEntity {
  __typename: 'RemoveEntityMutationPayload'
  errors: string | null
}

export interface deleteGroupEntityFdlm {
  removeEntity: deleteGroupEntityFdlm_removeEntity | null
}

export interface deleteGroupEntityFdlmVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonForFdlm
// ====================================================

export interface getPersonForFdlm_person_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface getPersonForFdlm_person_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: getPersonForFdlm_person_postsWithRel_edges_node_post
}

export interface getPersonForFdlm_person_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getPersonForFdlm_person_postsWithRel_edges_node | null
}

export interface getPersonForFdlm_person_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPersonForFdlm_person_postsWithRel_edges | null)[]
}

export interface getPersonForFdlm_person_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getPersonForFdlm_person_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getPersonForFdlm_person_artwork_renditions_thumbnail | null
}

export interface getPersonForFdlm_person_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPersonForFdlm_person_artwork_renditions | null
}

export interface getPersonForFdlm_person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  birthDate: any | null
  birthPlace: string | null
  postsWithRel: getPersonForFdlm_person_postsWithRel | null
  artwork: getPersonForFdlm_person_artwork | null
}

export interface getPersonForFdlm {
  person: getPersonForFdlm_person | null
}

export interface getPersonForFdlmVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutatePersonEntityFdlm
// ====================================================

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges_node_post
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges_node | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel_edges | null)[]
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork_renditions_thumbnail | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork_renditions | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_payload {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  birthDate: any | null
  birthPlace: string | null
  postsWithRel: mutatePersonEntityFdlm_mutatePersonEntity_payload_postsWithRel | null
  artwork: mutatePersonEntityFdlm_mutatePersonEntity_payload_artwork | null
}

export interface mutatePersonEntityFdlm_mutatePersonEntity_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface mutatePersonEntityFdlm_mutatePersonEntity {
  __typename: 'PersonEntityPayload'
  payload: mutatePersonEntityFdlm_mutatePersonEntity_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (mutatePersonEntityFdlm_mutatePersonEntity_errors | null)[] | null
}

export interface mutatePersonEntityFdlm {
  mutatePersonEntity: mutatePersonEntityFdlm_mutatePersonEntity | null
}

export interface mutatePersonEntityFdlmVariables {
  id: string
  name?: string | null
  birthDate?: any | null
  birthPlace?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePersonEntityFdlm
// ====================================================

export interface deletePersonEntityFdlm_removeEntity {
  __typename: 'RemoveEntityMutationPayload'
  errors: string | null
}

export interface deletePersonEntityFdlm {
  removeEntity: deletePersonEntityFdlm_removeEntity | null
}

export interface deletePersonEntityFdlmVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserEventPosts
// ====================================================

export interface getUserEventPosts_currentUser_postSet_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

export interface getUserEventPosts_currentUser_postSet_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getUserEventPosts_currentUser_postSet_edges_node | null
}

export interface getUserEventPosts_currentUser_postSet {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getUserEventPosts_currentUser_postSet_edges | null)[]
}

export interface getUserEventPosts_currentUser {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  postSet: getUserEventPosts_currentUser_postSet
}

export interface getUserEventPosts {
  currentUser: getUserEventPosts_currentUser | null
}

export interface getUserEventPostsVariables {
  fdlmTagSlug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDraftPost
// ====================================================

export interface createDraftPost_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

export interface createDraftPost_mutatePost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createDraftPost_mutatePost {
  __typename: 'PostMutationPayload'
  payload: createDraftPost_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createDraftPost_mutatePost_errors | null)[] | null
}

export interface createDraftPost {
  mutatePost: createDraftPost_mutatePost | null
}

export interface createDraftPostVariables {
  entity: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupsForEvent
// ====================================================

export interface getGroupsForEvent_post_postentityrelSet_edges_node_entity_Instrument {
  __typename: 'Instrument' | 'Style' | 'Track' | 'Tag' | 'PostList'
}

export interface getGroupsForEvent_post_postentityrelSet_edges_node_entity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getGroupsForEvent_post_postentityrelSet_edges_node_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export type getGroupsForEvent_post_postentityrelSet_edges_node_entity =
  | getGroupsForEvent_post_postentityrelSet_edges_node_entity_Instrument
  | getGroupsForEvent_post_postentityrelSet_edges_node_entity_Group
  | getGroupsForEvent_post_postentityrelSet_edges_node_entity_Person

export interface getGroupsForEvent_post_postentityrelSet_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  entity: getGroupsForEvent_post_postentityrelSet_edges_node_entity | null
}

export interface getGroupsForEvent_post_postentityrelSet_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getGroupsForEvent_post_postentityrelSet_edges_node | null
}

export interface getGroupsForEvent_post_postentityrelSet {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getGroupsForEvent_post_postentityrelSet_edges | null)[]
}

export interface getGroupsForEvent_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postentityrelSet: getGroupsForEvent_post_postentityrelSet
}

export interface getGroupsForEvent {
  /**
   * The ID of the object
   */
  post: getGroupsForEvent_post | null
}

export interface getGroupsForEventVariables {
  postId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addGroupToFdlm
// ====================================================

export interface addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges_node_post
}

export interface addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges_node | null
}

export interface addGroupToFdlm_mutateGroupEntity_payload_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (addGroupToFdlm_mutateGroupEntity_payload_postsWithRel_edges | null)[]
}

export interface addGroupToFdlm_mutateGroupEntity_payload_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface addGroupToFdlm_mutateGroupEntity_payload_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: addGroupToFdlm_mutateGroupEntity_payload_artwork_renditions_thumbnail | null
}

export interface addGroupToFdlm_mutateGroupEntity_payload_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: addGroupToFdlm_mutateGroupEntity_payload_artwork_renditions | null
}

export interface addGroupToFdlm_mutateGroupEntity_payload {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  startDate: any | null
  birthPlace: string | null
  postsWithRel: addGroupToFdlm_mutateGroupEntity_payload_postsWithRel | null
  artwork: addGroupToFdlm_mutateGroupEntity_payload_artwork | null
}

export interface addGroupToFdlm_mutateGroupEntity {
  __typename: 'GroupEntityPayload'
  payload: addGroupToFdlm_mutateGroupEntity_payload | null
}

export interface addGroupToFdlm {
  mutateGroupEntity: addGroupToFdlm_mutateGroupEntity | null
}

export interface addGroupToFdlmVariables {
  fdlmCollectionId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPersonToFdlm
// ====================================================

export interface addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges_node_post
}

export interface addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges_node | null
}

export interface addPersonToFdlm_mutatePersonEntity_payload_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (addPersonToFdlm_mutatePersonEntity_payload_postsWithRel_edges | null)[]
}

export interface addPersonToFdlm_mutatePersonEntity_payload_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface addPersonToFdlm_mutatePersonEntity_payload_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: addPersonToFdlm_mutatePersonEntity_payload_artwork_renditions_thumbnail | null
}

export interface addPersonToFdlm_mutatePersonEntity_payload_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: addPersonToFdlm_mutatePersonEntity_payload_artwork_renditions | null
}

export interface addPersonToFdlm_mutatePersonEntity_payload {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  birthDate: any | null
  birthPlace: string | null
  postsWithRel: addPersonToFdlm_mutatePersonEntity_payload_postsWithRel | null
  artwork: addPersonToFdlm_mutatePersonEntity_payload_artwork | null
}

export interface addPersonToFdlm_mutatePersonEntity {
  __typename: 'PersonEntityPayload'
  payload: addPersonToFdlm_mutatePersonEntity_payload | null
}

export interface addPersonToFdlm {
  mutatePersonEntity: addPersonToFdlm_mutatePersonEntity | null
}

export interface addPersonToFdlmVariables {
  fdlmCollectionId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutateEntityArtworkForFdlm
// ====================================================

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person {
  __typename: 'Person'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group {
  __typename: 'Group'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track {
  __typename: 'Track'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag {
  __typename: 'Tag'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument {
  __typename: 'Instrument'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style {
  __typename: 'Style'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style_artwork | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork_renditions_thumbnail | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork_renditions | null
}

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList {
  __typename: 'PostList'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  artwork: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList_artwork | null
}

export type mutateEntityArtworkForFdlm_mutateEntityArtwork_payload =
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Person
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Group
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Track
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Tag
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Instrument
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_Style
  | mutateEntityArtworkForFdlm_mutateEntityArtwork_payload_PostList

export interface mutateEntityArtworkForFdlm_mutateEntityArtwork {
  __typename: 'EntityArtworkMutationPayload'
  clientMutationId: string | null
  errors: string | null
  payload: mutateEntityArtworkForFdlm_mutateEntityArtwork_payload | null
}

export interface mutateEntityArtworkForFdlm {
  mutateEntityArtwork: mutateEntityArtworkForFdlm_mutateEntityArtwork | null
}

export interface mutateEntityArtworkForFdlmVariables {
  entityId: string
  artwork: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutateMediaFileForFdlM
// ====================================================

export interface mutateMediaFileForFdlM_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

export interface mutateMediaFileForFdlM_mutatePost_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface mutateMediaFileForFdlM_mutatePost {
  __typename: 'PostMutationPayload'
  payload: mutateMediaFileForFdlM_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (mutateMediaFileForFdlM_mutatePost_errors | null)[] | null
}

export interface mutateMediaFileForFdlM {
  mutatePost: mutateMediaFileForFdlM_mutatePost | null
}

export interface mutateMediaFileForFdlMVariables {
  id: string
  mediaFile?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRelForFdlm
// ====================================================

export interface createRelForFdlm_addPostEntityRelationship_rel {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
}

export interface createRelForFdlm_addPostEntityRelationship {
  __typename: 'AddPostEntityRelationshipPayload'
  rel: createRelForFdlm_addPostEntityRelationship_rel | null
  errors: string | null
}

export interface createRelForFdlm {
  addPostEntityRelationship: createRelForFdlm_addPostEntityRelationship | null
}

export interface createRelForFdlmVariables {
  postId: string
  entityId: string
  highlight?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostEventForImageGenerator
// ====================================================

export interface getPostEventForImageGenerator_post_artwork_renditions_medium {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostEventForImageGenerator_post_artwork_renditions {
  __typename: 'Renditions'
  medium: getPostEventForImageGenerator_post_artwork_renditions_medium | null
}

export interface getPostEventForImageGenerator_post_artwork {
  __typename: 'Original'
  renditions: getPostEventForImageGenerator_post_artwork_renditions | null
}

export interface getPostEventForImageGenerator_post_entities_edges_node_Instrument {
  __typename: 'Instrument' | 'Style' | 'Track' | 'Tag' | 'PostList'
}

export interface getPostEventForImageGenerator_post_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getPostEventForImageGenerator_post_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export type getPostEventForImageGenerator_post_entities_edges_node =
  | getPostEventForImageGenerator_post_entities_edges_node_Instrument
  | getPostEventForImageGenerator_post_entities_edges_node_Person
  | getPostEventForImageGenerator_post_entities_edges_node_Group

export interface getPostEventForImageGenerator_post_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostEventForImageGenerator_post_entities_edges_node | null
}

export interface getPostEventForImageGenerator_post_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostEventForImageGenerator_post_entities_edges | null)[]
}

export interface getPostEventForImageGenerator_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  locationName: string | null
  artwork: getPostEventForImageGenerator_post_artwork | null
  entities: getPostEventForImageGenerator_post_entities | null
}

export interface getPostEventForImageGenerator {
  /**
   * The ID of the object
   */
  post: getPostEventForImageGenerator_post | null
}

export interface getPostEventForImageGeneratorVariables {
  postId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: featuredUsers
// ====================================================

export interface featuredUsers_featuredUsers_edges_node {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  shortName: string
  bio: string | null
  avatar: string | null
  followersCount: number | null
}

export interface featuredUsers_featuredUsers_edges {
  __typename: 'UserEdge'
  /**
   * The item at the end of the edge
   */
  node: featuredUsers_featuredUsers_edges_node | null
}

export interface featuredUsers_featuredUsers {
  __typename: 'UserConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (featuredUsers_featuredUsers_edges | null)[]
}

export interface featuredUsers {
  featuredUsers: featuredUsers_featuredUsers | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFeedback
// ====================================================

export interface createFeedback_createFeedback_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createFeedback_createFeedback {
  __typename: 'CreateFeedbackMutationPayload'
  id: number | null
  screenshot: string | null
  url: string | null
  email: string | null
  comment: string | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createFeedback_createFeedback_errors | null)[] | null
}

export interface createFeedback {
  createFeedback: createFeedback_createFeedback | null
}

export interface createFeedbackVariables {
  comment: string
  browser: string
  url?: string | null
  email?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getHomePage
// ====================================================

export interface getHomePage_pages_edges_node_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getHomePage_pages_edges_node_background_renditions {
  __typename: 'Renditions'
  big: getHomePage_pages_edges_node_background_renditions_big | null
}

export interface getHomePage_pages_edges_node_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getHomePage_pages_edges_node_background_renditions | null
}

export interface getHomePage_pages_edges_node {
  __typename: 'Page'
  /**
   * The ID of the object.
   */
  id: string
  description: string | null
  background: getHomePage_pages_edges_node_background | null
}

export interface getHomePage_pages_edges {
  __typename: 'PageEdge'
  /**
   * The item at the end of the edge
   */
  node: getHomePage_pages_edges_node | null
}

export interface getHomePage_pages {
  __typename: 'PageConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getHomePage_pages_edges | null)[]
}

export interface getHomePage_heroSlides_edges_node_background_renditions_mobile {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getHomePage_heroSlides_edges_node_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getHomePage_heroSlides_edges_node_background_renditions {
  __typename: 'Renditions'
  mobile: getHomePage_heroSlides_edges_node_background_renditions_mobile | null
  big: getHomePage_heroSlides_edges_node_background_renditions_big | null
}

export interface getHomePage_heroSlides_edges_node_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getHomePage_heroSlides_edges_node_background_renditions | null
}

export interface getHomePage_heroSlides_edges_node {
  __typename: 'HeroSlide'
  /**
   * The ID of the object.
   */
  id: string
  title: string
  subtitle: string | null
  CTALabel: string | null
  CTATarget: string | null
  CTATargetAs: string | null
  textColor: string | null
  background: getHomePage_heroSlides_edges_node_background | null
}

export interface getHomePage_heroSlides_edges {
  __typename: 'HeroSlideEdge'
  /**
   * The item at the end of the edge
   */
  node: getHomePage_heroSlides_edges_node | null
}

export interface getHomePage_heroSlides {
  __typename: 'HeroSlideConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getHomePage_heroSlides_edges | null)[]
}

export interface getHomePage {
  pages: getHomePage_pages | null
  heroSlides: getHomePage_heroSlides | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getHomePageCustomBlocks
// ====================================================

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  thumbnail: string | null
  locationName: string | null
  authorFreetext: string | null
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events_edges_node | null
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events_edges | null)[]
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export type getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node =
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Person
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Group
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Style
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Tag
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Instrument
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_Track
  | getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node_PostList

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges_node | null
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities_edges | null)[]
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  body: string | null
  events: getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_events | null
  entities: getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection_entities | null
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges_node {
  __typename: 'HomePageCustomBlock'
  /**
   * The ID of the object.
   */
  id: string
  title: string
  subtitle: string | null
  CTALabel: string | null
  CTATarget: string | null
  CTATargetAs: string | null
  collection: getHomePageCustomBlocks_homePageCustomBlocks_edges_node_collection
}

export interface getHomePageCustomBlocks_homePageCustomBlocks_edges {
  __typename: 'HomePageCustomBlockEdge'
  /**
   * The item at the end of the edge
   */
  node: getHomePageCustomBlocks_homePageCustomBlocks_edges_node | null
}

export interface getHomePageCustomBlocks_homePageCustomBlocks {
  __typename: 'HomePageCustomBlockConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getHomePageCustomBlocks_homePageCustomBlocks_edges | null)[]
}

export interface getHomePageCustomBlocks {
  homePageCustomBlocks: getHomePageCustomBlocks_homePageCustomBlocks | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroup
// ====================================================

export interface getGroup_group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  birthPlace: string | null
}

export interface getGroup {
  group: getGroup_group | null
}

export interface getGroupVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getInstrument
// ====================================================

export interface getInstrument_instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getInstrument {
  instrument: getInstrument_instrument | null
}

export interface getInstrumentVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPerson
// ====================================================

export interface getPerson_person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  birthDate: any | null
  deathDate: any | null
  birthPlace: string | null
}

export interface getPerson {
  person: getPerson_person | null
}

export interface getPersonVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTrackForPostListItem
// ====================================================

export interface getTrackForPostListItem_postList_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
}

export interface getTrackForPostListItem_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  /**
   * Displayed in the article teaser
   */
  description: string | null
  createdBy: getTrackForPostListItem_postList_createdBy | null
}

export interface getTrackForPostListItem {
  postList: getTrackForPostListItem_postList | null
}

export interface getTrackForPostListItemVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getStyle
// ====================================================

export interface getStyle_style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
}

export interface getStyle {
  style: getStyle_style | null
}

export interface getStyleVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTag
// ====================================================

export interface getTag_tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  description: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
}

export interface getTag {
  tag: getTag_tag | null
}

export interface getTagVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTrack
// ====================================================

export interface getTrack_track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getTrack_track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getTrack_track_authors_edges_node | null
}

export interface getTrack_track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getTrack_track_authors_edges | null)[]
}

export interface getTrack_track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getTrack_track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getTrack_track_bands_edges_node | null
}

export interface getTrack_track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getTrack_track_bands_edges | null)[]
}

export interface getTrack_track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  date: any | null
  authors: getTrack_track_authors
  bands: getTrack_track_bands
}

export interface getTrack {
  track: getTrack_track | null
}

export interface getTrackVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUser
// ====================================================

export interface createUser_createUser_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createUser_createUser {
  __typename: 'CreateUserMutationPayload'
  email: string | null
  slug: string | null
  id: number | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createUser_createUser_errors | null)[] | null
}

export interface createUser {
  createUser: createUser_createUser | null
}

export interface createUserVariables {
  email: string
  slug: string
  password: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForMenu
// ====================================================

export interface CurrentUserForMenu_currentUser_groups_edges_node {
  __typename: 'DjangoGroup'
  /**
   * The ID of the object.
   */
  id: string
  name: string
}

export interface CurrentUserForMenu_currentUser_groups_edges {
  __typename: 'DjangoGroupEdge'
  /**
   * The item at the end of the edge
   */
  node: CurrentUserForMenu_currentUser_groups_edges_node | null
}

export interface CurrentUserForMenu_currentUser_groups {
  __typename: 'DjangoGroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (CurrentUserForMenu_currentUser_groups_edges | null)[]
}

export interface CurrentUserForMenu_currentUser {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  avatar: string | null
  /**
   * Designates whether the user can log into the admin site.
   */
  isStaff: boolean
  /**
   * Les groupes dont fait partie cet utilisateur. Celui-ci obtient tous les droits de tous les groupes auxquels il appartient.
   */
  groups: CurrentUserForMenu_currentUser_groups
}

export interface CurrentUserForMenu {
  currentUser: CurrentUserForMenu_currentUser | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEntitiesForPlayerStripe
// ====================================================

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Style {
  __typename: 'Style'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Tag {
  __typename: 'Tag'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Person {
  __typename: 'Person'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Group {
  __typename: 'Group'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Instrument {
  __typename: 'Instrument'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_PostList {
  __typename: 'PostList'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors_edges_node | null
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors_edges | null)[]
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands_edges_node | null
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands_edges | null)[]
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles_edges_node {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles_edges {
  __typename: 'StyleEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles_edges_node | null
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles {
  __typename: 'StyleConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles_edges | null)[]
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments_edges_node {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments_edges {
  __typename: 'InstrumentEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments_edges_node | null
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments {
  __typename: 'InstrumentConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments_edges | null)[]
}

export interface getEntitiesForPlayerStripe_post_entities_edges_node_Track {
  __typename: 'Track'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  authors: getEntitiesForPlayerStripe_post_entities_edges_node_Track_authors
  bands: getEntitiesForPlayerStripe_post_entities_edges_node_Track_bands
  styles: getEntitiesForPlayerStripe_post_entities_edges_node_Track_styles
  instruments: getEntitiesForPlayerStripe_post_entities_edges_node_Track_instruments
}

export type getEntitiesForPlayerStripe_post_entities_edges_node =
  | getEntitiesForPlayerStripe_post_entities_edges_node_Style
  | getEntitiesForPlayerStripe_post_entities_edges_node_Tag
  | getEntitiesForPlayerStripe_post_entities_edges_node_Person
  | getEntitiesForPlayerStripe_post_entities_edges_node_Group
  | getEntitiesForPlayerStripe_post_entities_edges_node_Instrument
  | getEntitiesForPlayerStripe_post_entities_edges_node_PostList
  | getEntitiesForPlayerStripe_post_entities_edges_node_Track

export interface getEntitiesForPlayerStripe_post_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForPlayerStripe_post_entities_edges_node | null
}

export interface getEntitiesForPlayerStripe_post_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForPlayerStripe_post_entities_edges | null)[]
}

export interface getEntitiesForPlayerStripe_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  entities: getEntitiesForPlayerStripe_post_entities | null
}

export interface getEntitiesForPlayerStripe {
  /**
   * The ID of the object
   */
  post: getEntitiesForPlayerStripe_post | null
}

export interface getEntitiesForPlayerStripeVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostsForPlayer
// ====================================================

export interface getPostsForPlayer_postsForPlayer_edges_node_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  displayName: string
}

export interface getPostsForPlayer_postsForPlayer_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  createdBy: getPostsForPlayer_postsForPlayer_edges_node_createdBy | null
}

export interface getPostsForPlayer_postsForPlayer_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostsForPlayer_postsForPlayer_edges_node | null
}

export interface getPostsForPlayer_postsForPlayer {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostsForPlayer_postsForPlayer_edges | null)[]
}

export interface getPostsForPlayer {
  postsForPlayer: getPostsForPlayer_postsForPlayer | null
}

export interface getPostsForPlayerVariables {
  post: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostImage
// ====================================================

export interface getPostImage_post_artwork_renditions_medium {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  height: number | null
  width: number | null
}

export interface getPostImage_post_artwork_renditions {
  __typename: 'Renditions'
  medium: getPostImage_post_artwork_renditions_medium | null
}

export interface getPostImage_post_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostImage_post_artwork_renditions | null
}

export interface getPostImage_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  artwork: getPostImage_post_artwork | null
}

export interface getPostImage {
  /**
   * The ID of the object
   */
  post: getPostImage_post | null
}

export interface getPostImageVariables {
  globalId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostMediaProvider
// ====================================================

export interface getPostMediaProvider_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  /**
   * Le media doit être compatible avec le player Mélo (Youtube, Soundcloud, dailymotion...)
   */
  mediaUrl: string | null
  mediaProvider: string | null
}

export interface getPostMediaProvider {
  /**
   * The ID of the object
   */
  post: getPostMediaProvider_post | null
}

export interface getPostMediaProviderVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProfile
// ====================================================

export interface getProfile_user_collectionSet_edges_node_createdBy {
  __typename: 'CurrentUser'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_collectionSet_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getProfile_user_collectionSet_edges_node_entities_edges_node =
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Person
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Group
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Tag
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Instrument
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Track
  | getProfile_user_collectionSet_edges_node_entities_edges_node_Style
  | getProfile_user_collectionSet_edges_node_entities_edges_node_PostList

export interface getProfile_user_collectionSet_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getProfile_user_collectionSet_edges_node_entities_edges_node | null
}

export interface getProfile_user_collectionSet_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (getProfile_user_collectionSet_edges_node_entities_edges | null)[]
}

export interface getProfile_user_collectionSet_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  createdBy: getProfile_user_collectionSet_edges_node_createdBy | null
  entities: getProfile_user_collectionSet_edges_node_entities | null
}

export interface getProfile_user_collectionSet_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: getProfile_user_collectionSet_edges_node | null
}

export interface getProfile_user_collectionSet {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getProfile_user_collectionSet_edges | null)[]
}

export interface getProfile_user_favoritesPosts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
}

export interface getProfile_user_favoritesPosts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getProfile_user_favoritesPosts_edges_node | null
}

export interface getProfile_user_favoritesPosts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getProfile_user_favoritesPosts_edges | null)[]
}

export interface getProfile_user {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  shortName: string
  slug: string
  avatar: string | null
  bio: string | null
  /**
   * Lien vers un site externe (site web, facebook, Intagram, Soundcloud etc...)
   */
  externalUrl: string | null
  followersCount: number | null
  followingCount: number | null
  collectionSet: getProfile_user_collectionSet
  favoritesPosts: getProfile_user_favoritesPosts | null
}

export interface getProfile {
  user: getProfile_user | null
}

export interface getProfileVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPage
// ====================================================

export interface getPage_pages_edges_node_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
}

export interface getPage_pages_edges_node_background_renditions {
  __typename: 'Renditions'
  big: getPage_pages_edges_node_background_renditions_big | null
}

export interface getPage_pages_edges_node_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPage_pages_edges_node_background_renditions | null
}

export interface getPage_pages_edges_node {
  __typename: 'Page'
  /**
   * The ID of the object.
   */
  id: string
  title: string
  description: string | null
  body: string | null
  css: string | null
  background: getPage_pages_edges_node_background | null
}

export interface getPage_pages_edges {
  __typename: 'PageEdge'
  /**
   * The item at the end of the edge
   */
  node: getPage_pages_edges_node | null
}

export interface getPage_pages {
  __typename: 'PageConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPage_pages_edges | null)[]
}

export interface getPage {
  pages: getPage_pages | null
}

export interface getPageVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEntityForLinkToEntity
// ====================================================

export interface getEntityForLinkToEntity_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getEntityForLinkToEntity_entity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getEntityForLinkToEntity_entity_Track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getEntityForLinkToEntity_entity_Track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntityForLinkToEntity_entity_Track_bands_edges_node | null
}

export interface getEntityForLinkToEntity_entity_Track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntityForLinkToEntity_entity_Track_bands_edges | null)[]
}

export interface getEntityForLinkToEntity_entity_Track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getEntityForLinkToEntity_entity_Track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntityForLinkToEntity_entity_Track_authors_edges_node | null
}

export interface getEntityForLinkToEntity_entity_Track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntityForLinkToEntity_entity_Track_authors_edges | null)[]
}

export interface getEntityForLinkToEntity_entity_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  bands: getEntityForLinkToEntity_entity_Track_bands
  authors: getEntityForLinkToEntity_entity_Track_authors
}

export interface getEntityForLinkToEntity_entity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getEntityForLinkToEntity_entity_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getEntityForLinkToEntity_entity_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  styleStartDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getEntityForLinkToEntity_entity_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getEntityForLinkToEntity_entity =
  | getEntityForLinkToEntity_entity_Person
  | getEntityForLinkToEntity_entity_Group
  | getEntityForLinkToEntity_entity_Track
  | getEntityForLinkToEntity_entity_Instrument
  | getEntityForLinkToEntity_entity_Tag
  | getEntityForLinkToEntity_entity_Style
  | getEntityForLinkToEntity_entity_PostList

export interface getEntityForLinkToEntity {
  entity: getEntityForLinkToEntity_entity | null
}

export interface getEntityForLinkToEntityVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllMediaFromPostListForPlayer
// ====================================================

export interface getAllMediaFromPostListForPlayer_postList_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getAllMediaFromPostListForPlayer_postList_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: getAllMediaFromPostListForPlayer_postList_postsWithRel_edges_node_post
}

export interface getAllMediaFromPostListForPlayer_postList_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getAllMediaFromPostListForPlayer_postList_postsWithRel_edges_node | null
}

export interface getAllMediaFromPostListForPlayer_postList_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getAllMediaFromPostListForPlayer_postList_postsWithRel_edges | null)[]
}

export interface getAllMediaFromPostListForPlayer_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  postsWithRel: getAllMediaFromPostListForPlayer_postList_postsWithRel | null
}

export interface getAllMediaFromPostListForPlayer {
  postList: getAllMediaFromPostListForPlayer_postList | null
}

export interface getAllMediaFromPostListForPlayerVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getChaptersOnly
// ====================================================

export interface getChaptersOnly_postListChapter_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getChaptersOnly_postListChapter_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  post: getChaptersOnly_postListChapter_edges_node_post
}

export interface getChaptersOnly_postListChapter_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getChaptersOnly_postListChapter_edges_node | null
}

export interface getChaptersOnly_postListChapter {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getChaptersOnly_postListChapter_edges | null)[]
}

export interface getChaptersOnly {
  postListChapter: getChaptersOnly_postListChapter | null
}

export interface getChaptersOnlyVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostsForPostListContext
// ====================================================

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  startDate: any | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  thumbnail: string | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  thumbnail: string | null
  styleStartDate: any | null
  endDate: any | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export type getPostsForPostListContext_postList_posts_edges_node_entities_edges_node =
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Track
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Person
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Group
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Instrument
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Style
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_Tag
  | getPostsForPostListContext_postList_posts_edges_node_entities_edges_node_PostList

export interface getPostsForPostListContext_postList_posts_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostsForPostListContext_postList_posts_edges_node_entities_edges_node | null
}

export interface getPostsForPostListContext_postList_posts_edges_node_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostsForPostListContext_postList_posts_edges_node_entities_edges | null)[]
}

export interface getPostsForPostListContext_postList_posts_edges_node {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  entities: getPostsForPostListContext_postList_posts_edges_node_entities | null
}

export interface getPostsForPostListContext_postList_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostsForPostListContext_postList_posts_edges_node | null
}

export interface getPostsForPostListContext_postList_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostsForPostListContext_postList_posts_edges | null)[]
}

export interface getPostsForPostListContext_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  posts: getPostsForPostListContext_postList_posts | null
}

export interface getPostsForPostListContext {
  postList: getPostsForPostListContext_postList | null
}

export interface getPostsForPostListContextVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEntitiesForSuggestion
// ====================================================

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
}

export type getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node =
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_PostList
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Track
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Style
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Person
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Group
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Tag
  | getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node_Instrument

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForSuggestion_postList_posts_edges_node_entities_edges_node | null
}

export interface getEntitiesForSuggestion_postList_posts_edges_node_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForSuggestion_postList_posts_edges_node_entities_edges | null)[]
}

export interface getEntitiesForSuggestion_postList_posts_edges_node {
  __typename: 'Post'
  entities: getEntitiesForSuggestion_postList_posts_edges_node_entities | null
}

export interface getEntitiesForSuggestion_postList_posts_edges {
  __typename: 'PostEdge'
  /**
   * The item at the end of the edge
   */
  node: getEntitiesForSuggestion_postList_posts_edges_node | null
}

export interface getEntitiesForSuggestion_postList_posts {
  __typename: 'PostConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getEntitiesForSuggestion_postList_posts_edges | null)[]
}

export interface getEntitiesForSuggestion_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  posts: getEntitiesForSuggestion_postList_posts | null
}

export interface getEntitiesForSuggestion {
  postList: getEntitiesForSuggestion_postList | null
}

export interface getEntitiesForSuggestionVariables {
  postListId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListChapterForMap
// ====================================================

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  lat: number | null
  lng: number | null
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  lat: number | null
  lng: number | null
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  lat: number | null
  lng: number | null
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  lat: number | null
  lng: number | null
  slug: string
  name: string | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  type: BaseNodeTypes
  slug: string
  name: string | null
}

export type getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node =
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Track
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Person
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Group
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Instrument
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Style
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_Tag
  | getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node_PostList

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListChapterForMap_postListChapter_edges_node_post_entities_edges_node | null
}

export interface getPostListChapterForMap_postListChapter_edges_node_post_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListChapterForMap_postListChapter_edges_node_post_entities_edges | null)[]
}

export interface getPostListChapterForMap_postListChapter_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  postType: PostPostType
  lat: number | null
  lng: number | null
  entities: getPostListChapterForMap_postListChapter_edges_node_post_entities | null
}

export interface getPostListChapterForMap_postListChapter_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  displayOnMap: boolean
  post: getPostListChapterForMap_postListChapter_edges_node_post
}

export interface getPostListChapterForMap_postListChapter_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListChapterForMap_postListChapter_edges_node | null
}

export interface getPostListChapterForMap_postListChapter {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListChapterForMap_postListChapter_edges | null)[]
}

export interface getPostListChapterForMap {
  postListChapter: getPostListChapterForMap_postListChapter | null
}

export interface getPostListChapterForMapVariables {
  slug: string
  chapter: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListMeta
// ====================================================

export interface getPostListMeta_postList_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  displayName: string
  shortName: string
  avatar: string | null
  bio: string | null
  followersCount: number | null
}

export interface getPostListMeta_postList_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getPostListMeta_postList_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getPostListMeta_postList_artwork_renditions_thumbnail | null
}

export interface getPostListMeta_postList_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListMeta_postList_artwork_renditions | null
}

export interface getPostListMeta_postList_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostListMeta_postList_background_renditions_mobile {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostListMeta_postList_background_renditions {
  __typename: 'Renditions'
  big: getPostListMeta_postList_background_renditions_big | null
  mobile: getPostListMeta_postList_background_renditions_mobile | null
}

export interface getPostListMeta_postList_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListMeta_postList_background_renditions | null
}

export interface getPostListMeta_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  /**
   * Displayed in the article teaser
   */
  description: string | null
  /**
   * Synopsis displayed at the article's beginning
   */
  abstract: string | null
  publishDate: any | null
  updated: any
  createdBy: getPostListMeta_postList_createdBy | null
  artwork: getPostListMeta_postList_artwork | null
  background: getPostListMeta_postList_background | null
  displayMap: boolean
}

export interface getPostListMeta {
  postList: getPostListMeta_postList | null
}

export interface getPostListMetaVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListChapterForStory
// ====================================================

export interface getPostListChapterForStory_postListChapter_pageInfo {
  __typename: 'PageInfo'
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands_edges_node | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands_edges | null)[]
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors_edges_node | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors_edges | null)[]
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  bands: getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_bands
  authors: getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track_authors
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  startDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
  styleStartDate: any | null
  endDate: any | null
  locationName: string | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity =
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Person
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Group
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Track
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Instrument
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Tag
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_Style
  | getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity_PostList

export interface getPostListChapterForStory_postListChapter_edges_node_post_artwork_renditions_medium {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  height: number | null
  width: number | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_artwork_renditions {
  __typename: 'Renditions'
  medium: getPostListChapterForStory_postListChapter_edges_node_post_artwork_renditions_medium | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListChapterForStory_postListChapter_edges_node_post_artwork_renditions | null
}

export interface getPostListChapterForStory_postListChapter_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  postType: PostPostType
  /**
   * Le media doit être compatible avec le player Mélo (Youtube, Soundcloud, dailymotion...)
   */
  mediaUrl: string | null
  mediaProvider: string | null
  duration: number | null
  mediaFile: string | null
  thumbnail: string | null
  html: string | null
  metadata: any | null
  lat: number | null
  lng: number | null
  body: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
  authorFreetext: string | null
  created: any
  updated: any
  linkToEntity: getPostListChapterForStory_postListChapter_edges_node_post_linkToEntity | null
  artwork: getPostListChapterForStory_postListChapter_edges_node_post_artwork | null
}

export interface getPostListChapterForStory_postListChapter_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  displayOnMap: boolean
  post: getPostListChapterForStory_postListChapter_edges_node_post
}

export interface getPostListChapterForStory_postListChapter_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListChapterForStory_postListChapter_edges_node | null
}

export interface getPostListChapterForStory_postListChapter {
  __typename: 'PostEntityRelConnection'
  /**
   * Pagination data for this connection.
   */
  pageInfo: getPostListChapterForStory_postListChapter_pageInfo
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListChapterForStory_postListChapter_edges | null)[]
}

export interface getPostListChapterForStory {
  postListChapter: getPostListChapterForStory_postListChapter | null
}

export interface getPostListChapterForStoryVariables {
  slug: string
  page: number
  cursor?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMetadata
// ====================================================

export interface getMetadata_mediaMetadata {
  __typename: 'MediaMetadata'
  name: string | null
  url: string | null
  duration: number | null
  html: string | null
  author: string | null
  provider: string | null
  description: string | null
  thumbnail: string | null
  thumbnailWidth: number | null
  thumbnailHeight: number | null
  error: string | null
}

export interface getMetadata {
  mediaMetadata: getMetadata_mediaMetadata | null
}

export interface getMetadataVariables {
  url: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createNewPostLinkToEntity
// ====================================================

export interface createNewPostLinkToEntity_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
}

export interface createNewPostLinkToEntity_mutatePost_errors {
  __typename: 'ErrorType'
  messages: string[]
  field: string
}

export interface createNewPostLinkToEntity_mutatePost {
  __typename: 'PostMutationPayload'
  payload: createNewPostLinkToEntity_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createNewPostLinkToEntity_mutatePost_errors | null)[] | null
}

export interface createNewPostLinkToEntity {
  mutatePost: createNewPostLinkToEntity_mutatePost | null
}

export interface createNewPostLinkToEntityVariables {
  insertAtPosition: number
  entity: string
  linkToEntityId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createNewPost
// ====================================================

export interface createNewPost_mutatePost_payload {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
}

export interface createNewPost_mutatePost_errors {
  __typename: 'ErrorType'
  messages: string[]
  field: string
}

export interface createNewPost_mutatePost {
  __typename: 'PostMutationPayload'
  payload: createNewPost_mutatePost_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createNewPost_mutatePost_errors | null)[] | null
}

export interface createNewPost {
  mutatePost: createNewPost_mutatePost | null
}

export interface createNewPostVariables {
  postType: string
  insertAtPosition: number
  entity: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListEditor
// ====================================================

export interface getPostListEditor_postList_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface getPostListEditor_postList_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getPostListEditor_postList_artwork_renditions_thumbnail | null
}

export interface getPostListEditor_postList_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListEditor_postList_artwork_renditions | null
}

export interface getPostListEditor_postList_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostListEditor_postList_background_renditions_mobile {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getPostListEditor_postList_background_renditions {
  __typename: 'Renditions'
  big: getPostListEditor_postList_background_renditions_big | null
  mobile: getPostListEditor_postList_background_renditions_mobile | null
}

export interface getPostListEditor_postList_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getPostListEditor_postList_background_renditions | null
}

export interface getPostListEditor_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  isPublic: boolean
  /**
   * Displayed in the article teaser
   */
  description: string | null
  thumbnail: string | null
  /**
   * Synopsis displayed at the article's beginning
   */
  abstract: string | null
  originalArtwork: string | null
  originalBackground: string | null
  artwork: getPostListEditor_postList_artwork | null
  background: getPostListEditor_postList_background | null
  displayMap: boolean
}

export interface getPostListEditor {
  postList: getPostListEditor_postList | null
}

export interface getPostListEditorVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeRelationship
// ====================================================

export interface removeRelationship_removePostEntityRelationship {
  __typename: 'RemovePostEntityRelationshipPayload'
  errors: string | null
}

export interface removeRelationship {
  removePostEntityRelationship: removeRelationship_removePostEntityRelationship | null
}

export interface removeRelationshipVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostRelationshipForEditor
// ====================================================

export interface getPostRelationshipForEditor_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getPostRelationshipForEditor {
  /**
   * The ID of the object
   */
  post: getPostRelationshipForEditor_post | null
}

export interface getPostRelationshipForEditorVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPostEntityRelationship
// ====================================================

export interface addPostEntityRelationship_addPostEntityRelationship_rel {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
}

export interface addPostEntityRelationship_addPostEntityRelationship {
  __typename: 'AddPostEntityRelationshipPayload'
  errors: string | null
  rel: addPostEntityRelationship_addPostEntityRelationship_rel | null
}

export interface addPostEntityRelationship {
  addPostEntityRelationship: addPostEntityRelationship_addPostEntityRelationship | null
}

export interface addPostEntityRelationshipVariables {
  post: string
  entity: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMutatePostList
// ====================================================

export interface createMutatePostList_createPostList_payload {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
}

export interface createMutatePostList_createPostList_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface createMutatePostList_createPostList {
  __typename: 'CreatePostListMutationPayload'
  payload: createMutatePostList_createPostList_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (createMutatePostList_createPostList_errors | null)[] | null
}

export interface createMutatePostList {
  createPostList: createMutatePostList_createPostList | null
}

export interface createMutatePostListVariables {
  slug: string
  name: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMutatePostList
// ====================================================

export interface updateMutatePostList_updatePostList_payload_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface updateMutatePostList_updatePostList_payload_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: updateMutatePostList_updatePostList_payload_artwork_renditions_thumbnail | null
}

export interface updateMutatePostList_updatePostList_payload_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: updateMutatePostList_updatePostList_payload_artwork_renditions | null
}

export interface updateMutatePostList_updatePostList_payload_background_renditions_big {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface updateMutatePostList_updatePostList_payload_background_renditions_mobile {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface updateMutatePostList_updatePostList_payload_background_renditions {
  __typename: 'Renditions'
  big: updateMutatePostList_updatePostList_payload_background_renditions_big | null
  mobile: updateMutatePostList_updatePostList_payload_background_renditions_mobile | null
}

export interface updateMutatePostList_updatePostList_payload_background {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: updateMutatePostList_updatePostList_payload_background_renditions | null
}

export interface updateMutatePostList_updatePostList_payload {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  /**
   * Displayed in the article teaser
   */
  description: string | null
  /**
   * Synopsis displayed at the article's beginning
   */
  abstract: string | null
  isPublic: boolean
  thumbnail: string | null
  originalArtwork: string | null
  originalBackground: string | null
  artwork: updateMutatePostList_updatePostList_payload_artwork | null
  background: updateMutatePostList_updatePostList_payload_background | null
}

export interface updateMutatePostList_updatePostList_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface updateMutatePostList_updatePostList {
  __typename: 'UpdatePostListMutationPayload'
  payload: updateMutatePostList_updatePostList_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors: (updateMutatePostList_updatePostList_errors | null)[] | null
}

export interface updateMutatePostList {
  updatePostList: updateMutatePostList_updatePostList | null
}

export interface updateMutatePostListVariables {
  id: string
  isPublic?: boolean | null
  name?: string | null
  description?: string | null
  abstract?: string | null
  originalArtwork?: string | null
  originalBackground?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutatePostListUpdateShowMap
// ====================================================

export interface mutatePostListUpdateShowMap_updatePostList_payload {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  displayMap: boolean
}

export interface mutatePostListUpdateShowMap_updatePostList {
  __typename: 'UpdatePostListMutationPayload'
  payload: mutatePostListUpdateShowMap_updatePostList_payload | null
}

export interface mutatePostListUpdateShowMap {
  updatePostList: mutatePostListUpdateShowMap_updatePostList | null
}

export interface mutatePostListUpdateShowMapVariables {
  id: string
  displayMap: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reorderPostListMutation
// ====================================================

export interface reorderPostListMutation_reorderPostList {
  __typename: 'ReorderPostListMutationPayload'
  clientMutationId: string | null
}

export interface reorderPostListMutation {
  reorderPostList: reorderPostListMutation_reorderPostList | null
}

export interface reorderPostListMutationVariables {
  postList: string
  postsRelationships: (string | null)[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostListsEntitiesForMapEditor
// ====================================================

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  lat: number | null
  lng: number | null
  thumbnail: string | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  lat: number | null
  lng: number | null
  thumbnail: string | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  lat: number | null
  lng: number | null
  thumbnail: string | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  type: BaseNodeTypes
  name: string | null
  lat: number | null
  lng: number | null
  thumbnail: string | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
}

export type getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity =
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Person
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Group
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Style
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Tag
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Instrument
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_PostList
  | getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity_Track

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  displayOnMap: boolean
  entity: getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node_entity | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges_node | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet_edges | null)[]
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  postType: PostPostType
  locationName: string | null
  lat: number | null
  lng: number | null
  body: string | null
  postentityrelSet: getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post_postentityrelSet
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  displayOnMap: boolean
  post: getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node_post
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: getPostListsEntitiesForMapEditor_postList_postsWithRel_edges_node | null
}

export interface getPostListsEntitiesForMapEditor_postList_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPostListsEntitiesForMapEditor_postList_postsWithRel_edges | null)[]
}

export interface getPostListsEntitiesForMapEditor_postList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  displayMap: boolean
  postsWithRel: getPostListsEntitiesForMapEditor_postList_postsWithRel | null
}

export interface getPostListsEntitiesForMapEditor {
  postList: getPostListsEntitiesForMapEditor_postList | null
}

export interface getPostListsEntitiesForMapEditorVariables {
  slug: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: mutatePostEntityRelationship
// ====================================================

export interface mutatePostEntityRelationship_postEntityRelationship_payload_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
}

export interface mutatePostEntityRelationship_postEntityRelationship_payload {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  displayOnMap: boolean
  post: mutatePostEntityRelationship_postEntityRelationship_payload_post
}

export interface mutatePostEntityRelationship_postEntityRelationship_errors {
  __typename: 'ErrorType'
  field: string
  messages: string[]
}

export interface mutatePostEntityRelationship_postEntityRelationship {
  __typename: 'PostEntityRelationshipMutationPayload'
  payload: mutatePostEntityRelationship_postEntityRelationship_payload | null
  /**
   * May contain more than one error for same field.
   */
  errors:
    | (mutatePostEntityRelationship_postEntityRelationship_errors | null)[]
    | null
}

export interface mutatePostEntityRelationship {
  postEntityRelationship: mutatePostEntityRelationship_postEntityRelationship | null
}

export interface mutatePostEntityRelationshipVariables {
  id: string
  displayOnMap: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTitleForMap
// ====================================================

export interface getTitleForMap_post_entities_edges_node_Instrument {
  __typename: 'Instrument' | 'Style' | 'Group' | 'Person' | 'Track' | 'Tag'
}

export interface getTitleForMap_post_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export type getTitleForMap_post_entities_edges_node =
  | getTitleForMap_post_entities_edges_node_Instrument
  | getTitleForMap_post_entities_edges_node_PostList

export interface getTitleForMap_post_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: getTitleForMap_post_entities_edges_node | null
}

export interface getTitleForMap_post_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getTitleForMap_post_entities_edges | null)[]
}

export interface getTitleForMap_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  chapterNumber: number | null
  entities: getTitleForMap_post_entities | null
}

export interface getTitleForMap {
  /**
   * The ID of the object
   */
  post: getTitleForMap_post | null
}

export interface getTitleForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventForMap
// ====================================================

export interface getEventForMap_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  thumbnail: string | null
  locationName: string | null
  authorFreetext: string | null
}

export interface getEventForMap {
  /**
   * The ID of the object
   */
  post: getEventForMap_post | null
}

export interface getEventForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupForMap
// ====================================================

export interface getGroupForMap_group_members_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getGroupForMap_group_members_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getGroupForMap_group_members_edges_node | null
}

export interface getGroupForMap_group_members {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getGroupForMap_group_members_edges | null)[]
}

export interface getGroupForMap_group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  birthPlace: string | null
  startDate: any | null
  endDate: any | null
  name: string | null
  thumbnail: string | null
  members: getGroupForMap_group_members
}

export interface getGroupForMap {
  group: getGroupForMap_group | null
}

export interface getGroupForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getImageForMap
// ====================================================

export interface getImageForMap_post_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface getImageForMap_post_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: getImageForMap_post_artwork_renditions_thumbnail | null
}

export interface getImageForMap_post_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: getImageForMap_post_artwork_renditions | null
}

export interface getImageForMap_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  artwork: getImageForMap_post_artwork | null
}

export interface getImageForMap {
  /**
   * The ID of the object
   */
  post: getImageForMap_post | null
}

export interface getImageForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getInstrumentTooltip
// ====================================================

export interface getInstrumentTooltip_instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  thumbnail: string | null
}

export interface getInstrumentTooltip {
  instrument: getInstrumentTooltip_instrument | null
}

export interface getInstrumentTooltipVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getVideoForMap
// ====================================================

export interface getVideoForMap_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
}

export interface getVideoForMap {
  /**
   * The ID of the object
   */
  post: getVideoForMap_post | null
}

export interface getVideoForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonForMap
// ====================================================

export interface getPersonForMap_person_groupSet_edges_node {
  __typename: 'Group'
  name: string | null
  /**
   * The ID of the object.
   */
  id: string
  slug: string
}

export interface getPersonForMap_person_groupSet_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getPersonForMap_person_groupSet_edges_node | null
}

export interface getPersonForMap_person_groupSet {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getPersonForMap_person_groupSet_edges | null)[]
}

export interface getPersonForMap_person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  birthPlace: string | null
  birthDate: any | null
  deathDate: any | null
  name: string | null
  thumbnail: string | null
  groupSet: getPersonForMap_person_groupSet
}

export interface getPersonForMap {
  person: getPersonForMap_person | null
}

export interface getPersonForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getStyleTooltip
// ====================================================

export interface getStyleTooltip_style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  thumbnail: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
}

export interface getStyleTooltip {
  style: getStyleTooltip_style | null
}

export interface getStyleTooltipVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTagForMap
// ====================================================

export interface getTagForMap_tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  description: string | null
  locationName: string | null
  startDate: any | null
  endDate: any | null
  thumbnail: string | null
}

export interface getTagForMap {
  tag: getTagForMap_tag | null
}

export interface getTagForMapVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTrackTooltip
// ====================================================

export interface getTrackTooltip_track_authors_edges_node {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getTrackTooltip_track_authors_edges {
  __typename: 'PersonEdge'
  /**
   * The item at the end of the edge
   */
  node: getTrackTooltip_track_authors_edges_node | null
}

export interface getTrackTooltip_track_authors {
  __typename: 'PersonConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getTrackTooltip_track_authors_edges | null)[]
}

export interface getTrackTooltip_track_bands_edges_node {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
}

export interface getTrackTooltip_track_bands_edges {
  __typename: 'GroupEdge'
  /**
   * The item at the end of the edge
   */
  node: getTrackTooltip_track_bands_edges_node | null
}

export interface getTrackTooltip_track_bands {
  __typename: 'GroupConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (getTrackTooltip_track_bands_edges | null)[]
}

export interface getTrackTooltip_track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  slug: string
  thumbnail: string | null
  date: any | null
  authors: getTrackTooltip_track_authors
  bands: getTrackTooltip_track_bands
}

export interface getTrackTooltip {
  track: getTrackTooltip_track | null
}

export interface getTrackTooltipVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPostForPlaylist
// ====================================================

export interface getPostForPlaylist_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  duration: number | null
  /**
   * Le media doit être compatible avec le player Mélo (Youtube, Soundcloud, dailymotion...)
   */
  mediaUrl: string | null
  html: string | null
  postType: PostPostType
  thumbnail: string | null
}

export interface getPostForPlaylist {
  /**
   * The ID of the object
   */
  post: getPostForPlaylist_post | null
}

export interface getPostForPlaylistVariables {
  objectId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostPart
// ====================================================

export interface PostPart_createdBy {
  __typename: 'User'
  /**
   * The ID of the object.
   */
  id: string
  displayName: string
  slug: string
  avatar: string | null
}

export interface PostPart {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  thumbnail: string | null
  duration: number | null
  lat: number | null
  lng: number | null
  postType: PostPostType
  body: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  locationName: string | null
  authorFreetext: string | null
  createdBy: PostPart_createdBy | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: collectionSetFrag
// ====================================================

export interface collectionSetFrag_edges_node_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_Tag {
  __typename: 'Tag'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_Instrument {
  __typename: 'Instrument'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_Track {
  __typename: 'Track'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_Style {
  __typename: 'Style'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export interface collectionSetFrag_edges_node_entities_edges_node_PostList {
  __typename: 'PostList'
  /**
   * The ID of the object.
   */
  id: string
  slug: string
  name: string | null
  thumbnail: string | null
}

export type collectionSetFrag_edges_node_entities_edges_node =
  | collectionSetFrag_edges_node_entities_edges_node_Person
  | collectionSetFrag_edges_node_entities_edges_node_Group
  | collectionSetFrag_edges_node_entities_edges_node_Tag
  | collectionSetFrag_edges_node_entities_edges_node_Instrument
  | collectionSetFrag_edges_node_entities_edges_node_Track
  | collectionSetFrag_edges_node_entities_edges_node_Style
  | collectionSetFrag_edges_node_entities_edges_node_PostList

export interface collectionSetFrag_edges_node_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: collectionSetFrag_edges_node_entities_edges_node | null
}

export interface collectionSetFrag_edges_node_entities {
  __typename: 'EntityConnection'
  totalCount: number | null
  /**
   * Contains the nodes in this connection.
   */
  edges: (collectionSetFrag_edges_node_entities_edges | null)[]
}

export interface collectionSetFrag_edges_node {
  __typename: 'Collection'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  body: string | null
  entities: collectionSetFrag_edges_node_entities | null
}

export interface collectionSetFrag_edges {
  __typename: 'CollectionEdge'
  /**
   * The item at the end of the edge
   */
  node: collectionSetFrag_edges_node | null
}

export interface collectionSetFrag {
  __typename: 'CollectionConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (collectionSetFrag_edges | null)[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostConnectionInGetMediaForEntityFdlm
// ====================================================

export interface PostConnectionInGetMediaForEntityFdlm_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
}

export interface PostConnectionInGetMediaForEntityFdlm_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: PostConnectionInGetMediaForEntityFdlm_edges_node_post
}

export interface PostConnectionInGetMediaForEntityFdlm_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: PostConnectionInGetMediaForEntityFdlm_edges_node | null
}

export interface PostConnectionInGetMediaForEntityFdlm {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (PostConnectionInGetMediaForEntityFdlm_edges | null)[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostConnectionInGetExternalUrlForEntityFdlm
// ====================================================

export interface PostConnectionInGetExternalUrlForEntityFdlm_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
}

export interface PostConnectionInGetExternalUrlForEntityFdlm_edges_node {
  __typename: 'PostEntityRel'
  order: number
  /**
   * The ID of the object.
   */
  id: string
  post: PostConnectionInGetExternalUrlForEntityFdlm_edges_node_post
}

export interface PostConnectionInGetExternalUrlForEntityFdlm_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: PostConnectionInGetExternalUrlForEntityFdlm_edges_node | null
}

export interface PostConnectionInGetExternalUrlForEntityFdlm {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (PostConnectionInGetExternalUrlForEntityFdlm_edges | null)[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupFdlm
// ====================================================

export interface GroupFdlm_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface GroupFdlm_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: GroupFdlm_postsWithRel_edges_node_post
}

export interface GroupFdlm_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: GroupFdlm_postsWithRel_edges_node | null
}

export interface GroupFdlm_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (GroupFdlm_postsWithRel_edges | null)[]
}

export interface GroupFdlm_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface GroupFdlm_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: GroupFdlm_artwork_renditions_thumbnail | null
}

export interface GroupFdlm_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: GroupFdlm_artwork_renditions | null
}

export interface GroupFdlm {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  startDate: any | null
  birthPlace: string | null
  postsWithRel: GroupFdlm_postsWithRel | null
  artwork: GroupFdlm_artwork | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonFdlm
// ====================================================

export interface PersonFdlm_postsWithRel_edges_node_post {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
}

export interface PersonFdlm_postsWithRel_edges_node {
  __typename: 'PostEntityRel'
  /**
   * The ID of the object.
   */
  id: string
  order: number
  post: PersonFdlm_postsWithRel_edges_node_post
}

export interface PersonFdlm_postsWithRel_edges {
  __typename: 'PostEntityRelEdge'
  /**
   * The item at the end of the edge
   */
  node: PersonFdlm_postsWithRel_edges_node | null
}

export interface PersonFdlm_postsWithRel {
  __typename: 'PostEntityRelConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (PersonFdlm_postsWithRel_edges | null)[]
}

export interface PersonFdlm_artwork_renditions_thumbnail {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
  width: number | null
  height: number | null
}

export interface PersonFdlm_artwork_renditions {
  __typename: 'Renditions'
  thumbnail: PersonFdlm_artwork_renditions_thumbnail | null
}

export interface PersonFdlm_artwork {
  __typename: 'Original'
  /**
   * The ID of the object.
   */
  id: string
  renditions: PersonFdlm_artwork_renditions | null
}

export interface PersonFdlm {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
  birthDate: any | null
  birthPlace: string | null
  postsWithRel: PersonFdlm_postsWithRel | null
  artwork: PersonFdlm_artwork | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditEventItem
// ====================================================

export interface EditEventItem {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  nameFr: string | null
  nameEn: string | null
  nameEs: string | null
  body: string | null
  bodyFr: string | null
  bodyEn: string | null
  bodyEs: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  thumbnail: string | null
  /**
   * Lien vers un site externe (site web, facebook live, Intagram etc...)
   */
  externalUrl: string | null
  locationName: string | null
  authorFreetext: string | null
  draft: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostForFdlmImage
// ====================================================

export interface PostForFdlmImage_artwork_renditions_medium {
  __typename: 'Rendition'
  /**
   * The ID of the object.
   */
  id: string
  href: string
}

export interface PostForFdlmImage_artwork_renditions {
  __typename: 'Renditions'
  medium: PostForFdlmImage_artwork_renditions_medium | null
}

export interface PostForFdlmImage_artwork {
  __typename: 'Original'
  renditions: PostForFdlmImage_artwork_renditions | null
}

export interface PostForFdlmImage_entities_edges_node_Instrument {
  __typename: 'Instrument' | 'Style' | 'Track' | 'Tag' | 'PostList'
}

export interface PostForFdlmImage_entities_edges_node_Person {
  __typename: 'Person'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export interface PostForFdlmImage_entities_edges_node_Group {
  __typename: 'Group'
  /**
   * The ID of the object.
   */
  id: string
  name: string | null
}

export type PostForFdlmImage_entities_edges_node =
  | PostForFdlmImage_entities_edges_node_Instrument
  | PostForFdlmImage_entities_edges_node_Person
  | PostForFdlmImage_entities_edges_node_Group

export interface PostForFdlmImage_entities_edges {
  __typename: 'EntityEdge'
  /**
   * The item at the end of the edge
   */
  node: PostForFdlmImage_entities_edges_node | null
}

export interface PostForFdlmImage_entities {
  __typename: 'EntityConnection'
  /**
   * Contains the nodes in this connection.
   */
  edges: (PostForFdlmImage_entities_edges | null)[]
}

export interface PostForFdlmImage {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  name: string | null
  startDate: any | null
  startTime: any | null
  endDate: any | null
  endTime: any | null
  timezone: string | null
  locationName: string | null
  artwork: PostForFdlmImage_artwork | null
  entities: PostForFdlmImage_entities | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventItem
// ====================================================

export interface EventItem {
  __typename: 'Post'
  /**
   * The ID of the object.
   */
  id: string
  postType: PostPostType
  /**
   * Combinaison of start date and time in the given timezone
   */
  startDatetime: any | null
  /**
   * Combinaison of end date and time in the given timezone
   */
  endDatetime: any | null
  name: string | null
  thumbnail: string | null
  locationName: string | null
  authorFreetext: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * All the kind of classse a BaseNode can be
 */
export enum BaseNodeTypes {
  GROUP = 'GROUP',
  INSTRUMENT = 'INSTRUMENT',
  PERSON = 'PERSON',
  POST = 'POST',
  POST_LIST = 'POST_LIST',
  STYLE = 'STYLE',
  TAG = 'TAG',
  TRACK = 'TRACK',
}

export enum EntityType {
  GROUP = 'GROUP',
  INSTRUMENT = 'INSTRUMENT',
  PERSON = 'PERSON',
  POSTLIST = 'POSTLIST',
  STYLE = 'STYLE',
  TAG = 'TAG',
  TRACK = 'TRACK',
}

/**
 * An enumeration.
 */
export enum PostPostType {
  EVENT = 'EVENT',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  IMAGE = 'IMAGE',
  LINK_TO_ENTITY = 'LINK_TO_ENTITY',
  LYRICS = 'LYRICS',
  MEDIA = 'MEDIA',
  QUOTE = 'QUOTE',
  SCORE = 'SCORE',
  STANDARD = 'STANDARD',
  TITLE = 'TITLE',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
