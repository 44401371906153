const { notNextJSRoutes } = require('./routes')
const NextI18Next = require('next-i18next').default

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['fr', 'es'],
  fallbackLng: 'fr',
  localeSubpaths: {
    en: 'en',
    es: 'es',
  },
  ignoreRoutes: notNextJSRoutes,
  // allow an empty value to count as invalid (by default is true)
  returnEmptyString: false,
})
