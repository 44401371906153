import { createAction } from 'redux-actions'
import find from 'lodash/find'
import { PostPostType } from '~/queries.d.ts'
import { AnyAction } from 'redux'
import Types from 'Types'
const RESET_CURRENT_POST = 'sphere/editor/RESET_CURRENT_POST'
const EDIT_POST = 'sphere/editor/EDIT_POST'
const UPDATE_CURRENT_POST = 'sphere/editor/UPDATE_CURRENT_POST'
const UPDATE_POST_LIST = 'sphere/editor/UPDATE_POST_LIST'
const SET_POST_LIST = 'sphere/editor/SET_POST_LIST'
const SET_POSTS = 'sphere/editor/SET_POSTS'
const RESET_EDITOR = 'sphere/editor/RESET_EDITOR'
const EDIT_POST_ID_FOR_LATER = 'sphere/editor/EDIT_POST_ID_FOR_LATER'
const SET_POST_LIST_MUTATION_RESULT =
  'sphere/editor/SET_POST_LIST_MUTATION_RESULT'
const SET_POST_LIST_MUTATION_DISABLED =
  'sphere/editor/SET_POST_LIST_MUTATION_DISABLED'

export enum DISABLED_REASON {
  UPLOADING_FILE,
}

interface Post {
  id: string
}
interface EditorState {
  currentPostObj?: Object | null
  editPostIdForLater?: string | null
  postList?: {
    id: string
  } | null
  posts?: Post[] | null
  postListMutation: {
    mutationResult: {
      loading: boolean
      data?: any
    }
    disabledReasons: {
      [DISABLED_REASON.UPLOADING_FILE]?: boolean
    }
  }
}
const initialState: EditorState = {
  currentPostObj: null,
  editPostIdForLater: null,
  postList: null,
  posts: null,
  postListMutation: {
    mutationResult: {
      loading: false,
      data: null,
    },
    disabledReasons: {},
  },
}

export default function (state = initialState, action: AnyAction): EditorState {
  switch (action.type) {
    case EDIT_POST:
      return {
        ...state,
        currentPostObj: { ...action.payload },
        editPostIdForLater: null,
      }
    case UPDATE_CURRENT_POST:
      return {
        ...state,
        currentPostObj: {
          ...(state.currentPostObj || {}),
          ...action.payload,
        },
      }
    case EDIT_POST_ID_FOR_LATER:
      return {
        ...state,
        editPostIdForLater: action.payload,
      }
    case SET_POST_LIST:
      return {
        ...state,
        postList: action.payload,
      }
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      }
    case UPDATE_POST_LIST:
      return {
        ...state,
        postList: {
          ...(state.postList || {}),
          ...action.payload,
        },
      }
    case SET_POST_LIST_MUTATION_RESULT:
      return {
        ...state,
        postListMutation: {
          ...state.postListMutation,
          mutationResult: action.payload,
        },
      }
    case SET_POST_LIST_MUTATION_DISABLED:
      return {
        ...state,
        postListMutation: {
          ...state.postListMutation,
          disabledReasons: {
            ...state.postListMutation.disabledReasons,
            [action.payload.reason]: action.payload.value,
          },
        },
      }
    case RESET_CURRENT_POST:
      return { ...state, currentPostObj: null }
    case RESET_EDITOR:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export const editPost = createAction(EDIT_POST)
export const editPostByIdWhenReady = (postId: string): Types.AppThunk => (
  dispatch,
  getState
) => {
  const post = find(getState().editor.posts, (post) => post.id === postId)
  if (!post) {
    dispatch({
      type: EDIT_POST_ID_FOR_LATER,
      payload: postId,
    })
  } else {
    dispatch(editPost(post))
  }
}
export const setPostListMutationResult = createAction(
  SET_POST_LIST_MUTATION_RESULT
)
export const setPostListMutationDisabledForReason = (
  reason: DISABLED_REASON,
  value: boolean
): Types.AppThunk => (dispatch, getState) => {
  if (getState().editor.postListMutation.disabledReasons[reason] !== value) {
    dispatch({
      type: SET_POST_LIST_MUTATION_DISABLED,
      payload: {
        reason,
        value,
      },
    })
  }
}
export const resetCurrentPost = createAction(RESET_CURRENT_POST)
export const resetEditor = createAction(RESET_EDITOR)
export const updateCurrentPost = createAction(UPDATE_CURRENT_POST)
export const updatePostList = createAction(UPDATE_POST_LIST)
export const setPostList = createAction(SET_POST_LIST)
export const setPosts = (
  posts: { post: any; id: string }[]
): Types.AppThunk => (dispatch, getState) => {
  let chapterCounter = 1
  dispatch({
    type: SET_POSTS,
    payload: posts.map(({ post, id }, index) => {
      let meta: {
        relId: string
        index: number
        chapterNumber?: number
      } = {
        relId: id,
        index,
      }
      if (post.postType === PostPostType.TITLE) {
        meta = {
          ...meta,
          chapterNumber: chapterCounter,
        }
        chapterCounter += 1
      }
      return {
        ...post,
        meta,
      }
    }),
  })
  const editPostIdForLater = getState().editor.editPostIdForLater
  if (!!editPostIdForLater) {
    dispatch(editPostByIdWhenReady(editPostIdForLater))
  }
}

export const selectPostListMutationDisabled = (state: Types.RootState) =>
  Object.values(state.editor.postListMutation.disabledReasons).some((d) => d)
