import { createAction } from 'redux-actions'
import { post, fetchJsonThunk } from '~/utils/fetch'
import initApollo from '~/utils/initApollo'
import cookie from 'js-cookie'
import nextCookie from 'next-cookies'
import { event as gaEvent } from '~/utils/gtag'
import { AnyAction } from 'redux'
const RESET = 'sphere/auth/RESET'
const ERROR = 'sphere/auth/ERROR'
const SET_TOKEN = 'sphere/auth/SET_TOKEN'
const SET_USER = 'sphere/auth/SET_USER'

interface State {
  loading: boolean
  token?: string
  error?: string
  user?: User
}

const initialState: State = {
  loading: true,
  token: undefined,
  error: undefined,
  user: undefined,
}

interface User {
  id: number
}

export const reducer = function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case RESET:
      return { ...initialState, loading: false }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        loading: false,
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const init = (ctx: any) => (dispatch: any) => {
  let token
  if (ctx) {
    token = nextCookie(ctx).token
  } else {
    token = cookie.get('token')
  }
  if (token) {
    return dispatch(verifyToken(token))
  } else {
    return dispatch(reset())
  }
}

export const verifyToken = (token: string) => (dispatch: any) =>
  post('/api/auth/jwt/verify/', `token=${token}`)
    .then(() => dispatch(successfulLogin(token)))
    .catch(() => dispatch(reset()))

export const authenticate = ({
  email,
  password,
}: {
  email: string
  password: string
}) => (dispatch: any) => {
  dispatch(reset())
  return post(
    '/api/auth/jwt/create/',
    `email=${email}&password=${password}`
  ).then(({ access }: { access: string }) => {
    gaEvent({ action: 'login' })
    return dispatch(successfulLogin(access))
  })
}

export const successfulLogin = (token: string) => (dispatch: any) => {
  cookie.set('token', token, { expires: 365, sameSite: 'lax' })
  dispatch({ type: SET_TOKEN, payload: token })
  return dispatch(fetchUser())
}

const fetchUser = () => (dispatch: any) =>
  dispatch(fetchJsonThunk('/api/auth/users/me/'))
    .then((user: User) => {
      if (user && user.id) {
        return dispatch(setUser(user))
      } else {
        return dispatch(reset())
      }
    })
    .catch(() => dispatch(reset()))

const setUser = (user: User) => ({ type: SET_USER, payload: user })
export const reset = () => (dispatch: any) => {
  const hasToken = !!cookie.get('token')
  if (hasToken) {
    cookie.remove('token')
    let apolloClient = initApollo()
    apolloClient.resetStore()
  }
  dispatch({ type: RESET })
}

export const error = createAction(ERROR)

export default reducer
