import { createAction } from 'redux-actions'

const OPEN = 'sphere/dialog/open'
const CLOSE = 'sphere/dialog/close'

export enum DIALOG_TYPE {
  EVENT,
}

type State =
  | {
      type: null
      params: null
    }
  | { type: DIALOG_TYPE.EVENT; params: { eventId: string } }

const initialState: State = {
  type: null,
  params: null,
}

type Actions =
  | {
      type: typeof OPEN
      payload: { type: DIALOG_TYPE.EVENT; params: { eventId: string } }
    }
  | { type: typeof CLOSE }

export const reducer = function (
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case OPEN:
      return {
        ...initialState,
        ...action.payload,
      }
    case CLOSE:
      return { ...initialState }
    default:
      return state
  }
}

const open = createAction<{ type: DIALOG_TYPE; params: any }>(OPEN)
export const openEvent = (eventId: string) => {
  return open({ type: DIALOG_TYPE.EVENT, params: { eventId } })
}
export const close = () => ({ type: CLOSE })

export default reducer
