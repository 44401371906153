const backendUrls = [
  '/server-static',
  '/graphql',
  '/api',
  '/meladmin',
  '/s3direct',
  '/fdlm-poster',
]

const notNextJSRoutes = ['/_next/', '/static/', '/public/', ...backendUrls]

const nextJSRoutesWithCache = [
  {
    match: '/p/:slug/edit',
    target: '/edit-parcours',
  },
  {
    match: '/p/:slug',
    target: '/parcours',
  },
  {
    match: '/notice/:slug',
    target: '/notice',
  },
  {
    match: '/profil/:slug',
    target: '/profil',
  },
  {
    match: '/collection/:oid',
    target: '/collection',
  },
  {
    match: '/fdlm-editor',
    target: '/fdlm-editor',
  },
  {
    match: '/fdlm-editor/:oid',
    target: '/fdlm-editor-details',
  },
]

const oldSlugs = [
  {
    old: "Cinq-nouvelles-nuances-d'amour",
    new: 'Cinq-nouvelles-nuances-damour',
  },
  {
    old: 'cinq-nouvelles-nuances-damour',
    new: 'Cinq-nouvelles-nuances-damour',
  },
  {
    old: "L'eau-sous-toutes-ses-formes",
    new: 'Leau-sous-toutes-ses-formes',
  },
  {
    old: 'hip-hop-au-plurielle',
    new: 'Hip-hop-au-plurielle',
  },
]

module.exports = {
  backendUrls,
  notNextJSRoutes,
  nextJSRoutesWithCache,
  oldSlugs,
}
