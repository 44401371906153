import Router from '~/utils/router'
import { AnyAction } from 'redux'
import { UrlObject } from 'url'

const PUSH = 'sphere/route/PUSH'

interface State {
  history: {
    href: string | UrlObject
    as: string
  }[]
}

const initialState: State = {
  history: [],
}

export const reducer = function (
  state = initialState,
  action: AnyAction
): State {
  switch (action.type) {
    case PUSH:
      return { ...initialState, history: [...state.history, action.payload] }
    default:
      return state
  }
}
export const pushToRouteHistory = () => ({
  type: PUSH,
  payload: {
    href: {
      pathname: Router.pathname,
      query: Router.query,
    },
    as: Router.asPath,
  },
})

export default reducer
