import { AnyAction } from 'redux'

const OPEN = 'sphere/toast/open'
const CLOSE = 'sphere/toast/close'

interface State {
  open: boolean
  message: string
  variant?: 'success' | 'error' | 'info' | 'warning'
}

const initialState: State = {
  open: false,
  message: '',
  variant: undefined,
}

export const reducer = function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case OPEN:
      return {
        ...initialState,
        ...action.payload,
        open: true,
      }
    case CLOSE:
      return {
        ...state,
        open: false,
      }
    default:
      return state
  }
}

export const open = (payload: Pick<State, 'message' | 'variant'>) => ({
  type: OPEN,
  payload,
})
export const close = () => ({ type: CLOSE })

export default reducer
