import Cookies from 'js-cookie'

const uri = process.browser ? '' : `http://127.0.0.1:${process.env.DJANGO_PORT}`

const getHeader = (options = {}) => {
  options = {
    ...{
      isJson: false,
    },
    ...options,
  }
  const csrfToken = Cookies.get('csrftoken')
  const header = {
    'X-CSRFToken': csrfToken,
    'Content-Type': options.isJson
      ? 'application/json'
      : 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    authorization: options.token ? `JWT ${options.token}` : undefined,
  }
  if (options.language) {
    header['Accept-Language'] = options.language
  }
  return header
}

export const fetchJson = (url, token, language) => {
  return fetch(uri + url, {
    headers: getHeader({ isJson: true, token, language }),
    credentials: 'same-origin',
  }).then((response) => response.json())
}

export const fetchRaw = (url) => {
  return fetch(uri + url, {
    headers: getHeader(),
    credentials: 'same-origin',
  })
}

export const post = (url, data, options = { isJson: false }) => {
  return fetch(uri + url, {
    method: 'POST',
    headers: getHeader(options),
    body: data,
    credentials: 'same-origin',
  }).then((response) => {
    if (response.ok) {
      return response.text().then((text) => (text ? JSON.parse(text) : {}))
    } else {
      return response.json().then((obj) => {
        throw obj
      })
    }
  })
}

export const postJson = (url, data, token) =>
  post(url, JSON.stringify(data), { isJson: true, token })

export const fetchJsonThunk = (url) => (dispatch, getState) => {
  const state = getState()
  const token = state.auth.token
  return fetchJson(url, token)
}

export const postJsonThunk = (url, data) => (dispatch, getState) => {
  const state = getState()
  const token = state.auth.token
  return postJson(url, data, token)
}
