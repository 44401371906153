import { createAction } from 'redux-actions'
import reject from 'lodash/reject'
import { fetchJson } from '~/utils/fetch'
import { AnyAction } from 'redux'
import Types from 'Types'
import { ThunkAction } from '~/reducers'
import Router from '~/utils/router'

const IS_READY = 'sphere/player/IS_READY'
const TOGGLE_PLAY = 'sphere/player/TOGGLE_PLAY'
const TOGGLE_MUTE = 'sphere/player/TOGGLE_MUTE'
const SET_FULLSCREEN = 'sphere/player/SET_FULLSCREEN'
const SET_IS_MOBILE = 'sphere/player/SET_IS_MOBILE'
const PLAY = 'sphere/player/PLAY'
const PLAY_NEXT = 'sphere/player/PLAY_NEXT'
const PLAY_PREVIOUS = 'sphere/player/PLAY_PREVIOUS'
const STOP = 'sphere/player/STOP'
const SET_PLAYLIST = 'sphere/player/SET_PLAYLIST'
const CLOSE = 'sphere/player/CLOSE'
const TOGGLE_PLAYLIST = 'sphere/player/TOGGLE_PLAYLIST'
const SET_FULL_PLAYLIST = 'sphere/player/SET_FULL_PLAYLIST'
const SET_PROGRESS = 'sphere/player/SET_PROGRESS'
const SEEK_TO = 'sphere/player/SEEK_TO'
const TOGGLE_CONTEXTUAL_NOTE = 'sphere/player/TOGGLE_CONTEXTUAL_NOTE'
const CONTEXTUAL_NOTE_SET = 'sphere/player/CONTEXTUAL_NOTE_SET'
const CONTEXTUAL_NOTE_NEXT = 'sphere/player/CONTEXTUAL_NOTE_NEXT'
const CONTEXTUAL_NOTE_PREPARE_NEXT =
  'sphere/player/CONTEXTUAL_NOTE_PREPARE_NEXT'
const TOGGLE_STRIPE_ALWAYS_VISIBLE =
  'sphere/player/TOGGLE_STRIPE_ALWAYS_VISIBLE'

const rejectNull = (source: any) => reject(source, (i) => i === null)

export interface Note {
  id: string
  name?: string | null
  body: string
  author?: {
    displayName: string
    slug: string
  }
}

interface PlayerState {
  open: boolean
  media?: string | null
  isPlaying: boolean
  isReady: boolean
  isVolumeMuted: boolean
  progress: number
  seekTo?: number | null
  playlist: string[]
  playlistTitle?: string | null
  playlistGlobalId?: string | null
  playlistSlug?: string | null
  history: string[]
  isMobile: boolean
  fullscreen: boolean
  showPlaylist: boolean
  stripeAlwaysVisibleInFullscreen: boolean
  contextualNotes: {
    history: Note[]
    next: Note[]
    current?: Note | null
    willDisapearSoon: boolean
    enable: boolean
  }
}

const initialState: PlayerState = {
  open: false,
  media: undefined,
  isReady: false,
  isPlaying: false,
  isVolumeMuted: false,
  progress: 0,
  seekTo: null,
  playlist: [],
  playlistTitle: undefined,
  playlistGlobalId: undefined,
  playlistSlug: undefined,
  history: [],
  isMobile: false,
  fullscreen: false,
  showPlaylist: false,
  stripeAlwaysVisibleInFullscreen: true,
  contextualNotes: {
    enable: true,
    history: [],
    next: [],
    current: null,
    willDisapearSoon: false,
  },
}

export const reducer = function (
  state: PlayerState = initialState,
  action: AnyAction
): PlayerState {
  switch (action.type) {
    case IS_READY:
      return {
        ...state,
        isReady: true,
      }
    case TOGGLE_PLAY:
      return {
        ...state,
        isPlaying:
          action.payload !== undefined ? action.payload : !state.isPlaying,
      }
    case TOGGLE_PLAYLIST:
      return {
        ...state,
        showPlaylist: !state.showPlaylist,
      }
    case TOGGLE_CONTEXTUAL_NOTE:
      return {
        ...state,
        contextualNotes: {
          ...state.contextualNotes,
          enable: !state.contextualNotes.enable,
        },
      }
    case TOGGLE_MUTE:
      return {
        ...state,
        isVolumeMuted: !state.isVolumeMuted,
      }
    case TOGGLE_STRIPE_ALWAYS_VISIBLE:
      return {
        ...state,
        stripeAlwaysVisibleInFullscreen: !state.stripeAlwaysVisibleInFullscreen,
      }
    case SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.payload,
      }
    case SET_PROGRESS:
      return {
        ...state,
        progress: state.seekTo ? state.seekTo : action.payload,
      }
    case SEEK_TO:
      return {
        ...state,
        seekTo: action.payload,
        progress: action.payload ? action.payload : state.progress,
      }
    case PLAY:
      return {
        ...state,
        open: true,
        isPlaying: true,
        media: action.payload.objectId,
        history: [],
        playlist: [],
        progress: 0,
      }
    case PLAY_NEXT:
      return {
        ...state,
        media: state.playlist.length > 0 ? state.playlist[0] : null,
        playlist: state.playlist.slice(1),
        history: rejectNull([state.media, ...state.history]),
        progress: 0,
      }
    case PLAY_PREVIOUS:
      return {
        ...state,
        media: state.history.length > 0 ? state.history[0] : null,
        playlist: rejectNull([state.media, ...state.history]),
        history: state.history.slice(1),
        progress: 0,
      }
    case STOP:
      return {
        ...state,
        isPlaying: false,
        progress: 0,
      }
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: true,
        showPlaylist: false,
        fullscreen: true,
        stripeAlwaysVisibleInFullscreen: true,
      }
    case SET_PLAYLIST:
      return {
        ...state,
        playlistTitle: action.payload.title,
        playlistGlobalId: action.payload.globalId,
        playlistSlug: action.payload.slug,
        playlist: [...action.payload.playlist],
      }
    case SET_FULL_PLAYLIST:
      return {
        ...state,
        history: action.payload.history,
        media: action.payload.media,
        playlist: action.payload.playlist,
      }
    case CONTEXTUAL_NOTE_SET:
      return {
        ...state,
        contextualNotes: {
          ...state.contextualNotes,
          next: action.payload,
          current: null,
          willDisapearSoon: false,
        },
      }
    case CONTEXTUAL_NOTE_PREPARE_NEXT:
      return {
        ...state,
        contextualNotes: {
          ...state.contextualNotes,
          willDisapearSoon: true,
        },
      }
    case CONTEXTUAL_NOTE_NEXT:
      return {
        ...state,
        contextualNotes: {
          ...state.contextualNotes,
          history: state.contextualNotes.current
            ? [state.contextualNotes.current, ...state.contextualNotes.history]
            : state.contextualNotes.history,
          current: state.contextualNotes.next[0],
          next: state.contextualNotes.next.slice(1),
          willDisapearSoon: false,
        },
      }
    case CLOSE:
      return {
        ...initialState,
        // keep the playlistGlobalId b/c it is can be used in PlayerLoader to
        // load the related page later
        playlistGlobalId: state.playlistGlobalId,
        playlistSlug: state.playlistSlug,
      }
    default:
      return state
  }
}

export const setIsReady = createAction<void>(IS_READY)
export const setPlaylist = createAction<{
  globalId?: string
  slug?: string | null
  title: string
  playlist: string[]
}>(SET_PLAYLIST)
export const togglePlay = createAction<boolean | void>(TOGGLE_PLAY)
export const toggleMute = createAction(TOGGLE_MUTE)
export const togglePlaylist = createAction(TOGGLE_PLAYLIST)
export const toggleStripeAlwaysVisibleInFullscreen = createAction(
  TOGGLE_STRIPE_ALWAYS_VISIBLE
)
export const setIsMobile = createAction(SET_IS_MOBILE)

export const setFullscreen = (isFullscreen: boolean): ThunkAction<void> => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_FULLSCREEN, payload: isFullscreen })
  let { playlistGlobalId } = getState().player
  if (playlistGlobalId) {
    if (isFullscreen && Router.pathname !== '/player/[oid]') {
      Router.push(
        {
          pathname: '/player/[oid]',
          query: {
            oid: playlistGlobalId,
          },
        },
        `/player/${playlistGlobalId}`
      )
    }
  }
}
export const toggleFullscreen = (): ThunkAction<void> => (dispatch, getState) =>
  dispatch(setFullscreen(!getState().player.fullscreen))

export const urlHasChanged = (url: string): ThunkAction<void> => (
  dispatch,
  getState
) => {
  const { isMobile, fullscreen, media } = getState().player
  // when url changes, we close the player or reduce the fullscreen
  // if not the fullscreen url itself
  if (
    media &&
    !(
      url.startsWith('/player') ||
      url.startsWith('/en/player') ||
      url.startsWith('/es/player')
    )
  ) {
    if (isMobile) {
      dispatch(close())
    } else if (fullscreen) {
      dispatch(setFullscreen(false))
    }
  }
}

export const play = createAction<{ objectId: string }>(PLAY)
export const playNext = createAction(PLAY_NEXT)
export const playPrevious = createAction(PLAY_PREVIOUS)
export const stop = createAction(STOP)
export const close = createAction(CLOSE)
export const setProgress = createAction(SET_PROGRESS)
export const seekTo = createAction(SEEK_TO)
export const playAndSetPlaylist = ({
  objectId,
  playlistTitle,
  playlist = [],
  playlistGlobalId,
}: {
  objectId: string
  playlistTitle: string
  playlist?: string[] | null
  playlistGlobalId?: string
}): ThunkAction<void> => (dispatch) => {
  dispatch(play({ objectId }))
  dispatch(
    setPlaylist({
      globalId: playlistGlobalId,
      title: playlistTitle,
      playlist: playlist || [],
    })
  )
}
export const playIndexInCurrentPlaylist = (index: number): ThunkAction<any> => (
  dispatch,
  getState
) => {
  let playlist = selectPlaylist(getState())
  let nextMedia = playlist[index]
  let nextHistory = playlist.slice(0, index).reverse()
  let nextPlaylist = playlist.slice(index + 1)
  return dispatch(
    setFullPlaylist({
      history: nextHistory,
      media: nextMedia,
      playlist: nextPlaylist,
    })
  )
}
const setFullPlaylist = createAction<{
  history: string[]
  media: string
  playlist: string[]
}>(SET_FULL_PLAYLIST)

export const searchAndPlayResults = ({
  objectId,
  playlistTitle,
  playlistSlug,
  random,
}: {
  objectId: string
  playlistTitle: string
  playlistSlug?: string | null
  random?: boolean
}): ThunkAction<Promise<any>> => (dispatch, getState): Promise<any> => {
  let url = `/api/v1/search/?page_size=30&text=${objectId}&post_type=media`
  if (random) {
    const randomSeed = getState().search.randomSeed
    url += `&order=?${randomSeed}`
  }
  return fetchJson(url).then((d: any) => {
    if (d.results && d.results.length > 0) {
      dispatch(play({ objectId: d.results[0].global_id }))
      dispatch(
        setPlaylist({
          title: playlistTitle,
          slug: playlistSlug,
          globalId: objectId,
          playlist: d.results.slice(1).map((r: any) => r.global_id),
        })
      )
    } else {
      throw new Error('no-media')
    }
  })
}

export const toggleContextualNotes = createAction<void>(TOGGLE_CONTEXTUAL_NOTE)
export const setContextualNotes = createAction<Note[]>(CONTEXTUAL_NOTE_SET)
export const nextContextualNote = createAction<void>(CONTEXTUAL_NOTE_NEXT)
export const prepareContextualNoteToDisapear = createAction<void>(
  CONTEXTUAL_NOTE_PREPARE_NEXT
)
export default reducer

// SELECTORS
export const selectHasNext = (state: Types.RootState) =>
  state.player.playlist.length > 0
export const selectHasPrevious = (state: Types.RootState) =>
  state.player.history.length > 0
export const selectPlaylist = (state: Types.RootState): string[] =>
  [
    ...state.player.history.slice().reverse(),
    state.player.media,
    ...state.player.playlist,
  ].filter((d) => !!d) as string[]
