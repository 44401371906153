import { createAction } from 'redux-actions'
import { AnyAction } from 'redux'

const RESET = 'sphere/header/RESET'
const SET = 'sphere/header/SET'
const SET_SUBTITLE = 'sphere/header/SET_SUBTITLE'
const SET_CHAPTER = 'sphere/header/SET_CHAPTER'

export enum HEADER_THEMES {
  DEFAULT,
  ENTITY,
}

const initialState = {
  title: null,
  subtitle: null,
  chapter: null,
  theme: HEADER_THEMES.DEFAULT,
}

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET:
      return {
        ...initialState,
        ...action.payload,
      }
    case SET_SUBTITLE:
      return {
        ...state,
        subtitle: action.payload,
        chapter: null,
      }
    case SET_CHAPTER:
      return {
        ...state,
        chapter: action.payload,
        subtitle: null,
      }
    case RESET:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export const setHeader = createAction(SET)
export const setSubtitle = createAction(SET_SUBTITLE)
export const setChapter = createAction(SET_CHAPTER)
export const resetHeader = createAction(RESET)
