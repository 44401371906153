import { createAction } from 'redux-actions'
import { fetchJson } from '~/utils/fetch'
import { MAP_SIZES_STATUS } from '~/constantes'
import MapType from '~/components/Map/Map'
import { AnyAction } from 'redux'
import { ThunkAction } from '~/reducers'

const RESET = 'sphere/map/RESET'
const SET_MARKERS = 'sphere/map/SET_MARKERS'
const SET_POPUP = 'sphere/map/SET_POPUP'
const SET_TOOLTIP = 'sphere/map/SET_TOOLTIP'
const CLEAR_TOOLTIP = 'sphere/map/CLEAR_TOOLTIP'
const SET_SMALLER = 'sphere/map/SET_SMALLER'
const SET_LARGER = 'sphere/map/SET_LARGER'
const DISABLE = 'sphere/map/DISABLE'
const HIDE_TEMP = 'sphere/map/HIDE_TEMP'
const SET_FOCUS_GID = 'sphere/map/SET_FOCUS_GID'

interface SetMarkersOptions {
  fitBoundsOptions?: {
    padding?:
      | number
      | { top: number; bottom: number; left: number; right: number }
  }
}

interface State {
  size: MAP_SIZES_STATUS
  markers: MapType.Marker[]
  popup: any | null
  tooltip: any | null
  disable: boolean
  isClearingTooltip: boolean
  options: SetMarkersOptions
  hideTemp: false | MAP_SIZES_STATUS
  focusGId: string | null
}

const initialState: State = {
  markers: [],
  popup: null,
  tooltip: null,
  size: MAP_SIZES_STATUS.SMALL,
  disable: false,
  isClearingTooltip: false,
  options: {},
  hideTemp: false,
  focusGId: null,
}

export default function (
  state: State = initialState,
  action: AnyAction
): State {
  switch (action.type) {
    case SET_SMALLER:
      return {
        ...state,
        size: Math.max(MAP_SIZES_STATUS.CLOSE, state.size - 1),
      }
    case SET_LARGER:
      return {
        ...state,
        size: Math.min(MAP_SIZES_STATUS.LARGE, state.size + 1),
      }
    case HIDE_TEMP:
      return {
        ...state,
        size: MAP_SIZES_STATUS.CLOSE,
        hideTemp: state.size,
      }
    case SET_MARKERS:
      return {
        ...state,
        markers: action.payload as MapType.Marker[],
        options: action.options,
        disable: false,
        popup: null,
        hideTemp: false,
        // focusGId: null,
        tooltip: null,
        size:
          state.hideTemp === false
            ? state.size
            : (state.hideTemp as MAP_SIZES_STATUS),
      }
    case SET_POPUP:
      return {
        ...state,
        popup: action.payload,
        tooltip: null,
      }
    case SET_TOOLTIP:
      return {
        ...state,
        tooltip: action.payload,
        isClearingTooltip: false,
      }
    case CLEAR_TOOLTIP:
      return {
        ...state,
        isClearingTooltip: true,
      }
    case DISABLE:
      return {
        ...state,
        disable: !!action.payload,
      }
    case SET_FOCUS_GID:
      return {
        ...state,
        focusGId: action.payload,
      }
    case RESET:
      return { ...initialState }
    default:
      return state
  }
}

export const setMarkers = (
  markers: MapType.Marker[],
  options?: SetMarkersOptions
) => ({
  type: SET_MARKERS,
  payload: markers,
  options: options || {},
})

export const setPopup = createAction(SET_POPUP)
const setTooltip = createAction(SET_TOOLTIP)
export const setTooltipIfPossible = (payload: any): ThunkAction<void> => (
  dispatch,
  getState
) => {
  const { popup } = getState().map
  if (!popup || popup.id !== payload.id) {
    dispatch(setTooltip(payload))
  }
}
export const clearTooltip = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: CLEAR_TOOLTIP })
  setTimeout(() => {
    if (getState().map.isClearingTooltip) {
      dispatch(setTooltip(null))
    }
  }, 2000)
}
export const resetMap = createAction(RESET)
export const setSmaller = createAction(SET_SMALLER)
export const setLarger = createAction(SET_LARGER)
export const disable = createAction(DISABLE)
export const hideMapTemporarly = (): ThunkAction<void> => (
  dispatch,
  getState
) => {
  if (getState().map.hideTemp === false) {
    dispatch({
      type: HIDE_TEMP,
    })
  }
}

export const mapLoadPostList = (objectId: string): ThunkAction<void> => (
  dispatch
) => {
  return dispatch(mapLoad(`text=${objectId}`))
}

export const mapLoad = (params: string): ThunkAction<Promise<any>> => (
  dispatch
) => {
  return fetchJson(`/api/v1/map/?${params}`).then(
    (data: MapType.ApiPayload) => {
      dispatch(setMarkers(convertSearchAPIResultsToMarkers(data.results)))
    }
  )
}

export const setMarkersFromApiResults = (results: MapType.ApiResult[]) => {
  return setMarkers(convertSearchAPIResultsToMarkers(results))
}
export const focusOnGlobalId = createAction<string | null>(SET_FOCUS_GID)

function convertSearchAPIResultsToMarkers(
  results: MapType.ApiResult[]
): MapType.Marker[] {
  const markers: MapType.Marker[] = []
  results.forEach((res) => {
    markers.push({
      id: res.global_id,
      lat: parseFloat(res.lat),
      lng: parseFloat(res.lng),
      name: res.name,
      thumbnail: res.thumbnail,
      postType:
        !!res.post_type && (res.post_type.toUpperCase() as MapType.postTypes),
      slug: res.slug,
      type: res.type.toUpperCase() as MapType.markerTypes,
    })
  })
  return markers
}
