import fetch from 'isomorphic-unfetch'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fragmentMatcher from '~/utils/fragmentMatcher'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from 'apollo-link-context'

let apolloClient = null

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch
}

const uri = process.browser
  ? `/graphql-batch/`
  : `http://127.0.0.1:${process.env.DJANGO_PORT}/graphql-batch/`

function create(initialState, options) {
  const httpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
  })

  const authLink = setContext((_, { headers }) => {
    const token = options?.getToken()
    const ln = options?.language()
    return {
      headers: {
        ...headers,
        authorization: token ? 'JWT ' + token : '',
        'Accept-Language': ln || '',
      },
    }
  })

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState || {}),
  })
}

export default function initApollo(initialState, options = {}) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options)
  }
  return apolloClient
}
