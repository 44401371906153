import { createAction } from 'redux-actions'
import { fetchJson } from '~/utils/fetch'
import { RANGE_DATES } from '~/constantes'
import { event as gaEvent } from '~/utils/gtag'
import Router from '~/utils/router'

const CLEAR = 'sphere/search/CLEAR'
const SET_TERMS = 'sphere/search/SET_TERMS'
const RECEIVE_RESULTS = 'sphere/search/RECEIVE_RESULTS'
const RECEIVE_NEXT_RESULTS = 'sphere/search/RECEIVE_NEXT_RESULTS'
const SEARCH = 'sphere/search/SEARCH'
const SHOW = 'sphere/search/SHOW'
const HIDE = 'sphere/search/HIDE'
const SET_FILTER = 'sphere/search/SET_FILTER'
const SET_ORDER = 'sphere/search/SET_ORDER'
const SET_DATES_RANGE = 'sphere/search/SET_DATES_RANGE'
const SET_SEED = 'sphere/search/SET_SEED'

const initialState = {
  searchTerms: '',
  show: false,
  autoFocus: true,
  results: null,
  count: null,
  next: null,
  sortByTime: true,
  datesRange: RANGE_DATES,
  filter: '',
  randomSeed: null,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TERMS:
      return {
        ...state,
        searchTerms: action.payload,
        autoFocus: false,
      }
    case RECEIVE_RESULTS:
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
      }
    case RECEIVE_NEXT_RESULTS:
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        results: [...state.results, ...action.payload.results],
      }
    case CLEAR:
      return { ...initialState }
    case SHOW:
      return {
        ...state,
        show: true,
        autoFocus: true,
      }
    case SET_SEED:
      return {
        ...state,
        randomSeed: action.payload,
      }
    case HIDE:
      return {
        ...state,
        show: false,
        autoFocus: false,
      }
    case SEARCH:
      return {
        ...state,
        show: false,
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    case SET_ORDER:
      return {
        ...state,
        sortByTime: action.payload === 'time',
      }
    case SET_DATES_RANGE:
      return {
        ...state,
        datesRange: action.payload,
      }
    default:
      return state
  }
}

const searchWithFilters = () => (dispatch, getState) => {
  const { filters, searchTerms, datesRange } = getState().search
  const filtersStr = Object.keys(filters)
    .filter((f) => filters[f])
    .join(',')

  let url = `?text=${searchTerms}&type=${filtersStr}`

  if (datesRange[0] !== RANGE_DATES[0] || datesRange[1] !== RANGE_DATES[1]) {
    url += `&start_year__lte=${datesRange[1]}&end_year__gt=${datesRange[0]}`
  }

  fetchJson(`/api/v1/map/${url}`).then((results) => {
    // if it's still the same request
    if (searchTerms === getState().search.searchTerms) {
      // dispatch(mapActions.filterIds(null))
      // dispatch(mapActions.setMarkers(results.results))
    }
  })

  return fetchJson(`/api/v1/search/${url}`).then((results) => {
    // if it's still the same request
    if (searchTerms === getState().search.searchTerms) {
      dispatch(receiveResults(results))
    }
  })
}

export const setTerms = createAction(SET_TERMS)
export const setSeed = createAction(SET_SEED)

export const search = () => (dispatch, getState) => {
  const searchTerms = getState().search.searchTerms
  dispatch({ type: SEARCH })
  if (searchTerms.length === 0) {
    console.error('nothing to search for')
  }
  gaEvent({
    action: 'search',
    category: 'engagement',
    label: 'search_term',
    value: searchTerms,
  })
  Router.push(`/recherche?q=${searchTerms}`)
}

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_FILTER, payload })
}

export const randomizeSeed = () => {
  return setSeed(generateSeed())
}

export const setDatesRange = (payload) => (dispatch) => {
  dispatch({ type: SET_DATES_RANGE, payload })
  // return dispatch(searchWithFilters())
}

export const setOrder = (payload) => (dispatch) => {
  dispatch({ type: SET_ORDER, payload })
  return dispatch(searchWithFilters())
}

const receiveResults = createAction(RECEIVE_RESULTS)
export const show = createAction(SHOW)
export const hide = createAction(HIDE)
const receiveNextResults = createAction(RECEIVE_NEXT_RESULTS)
export const clearSearch = createAction(CLEAR)

export const loadMore = () => (dispatch, getState) => {
  const nextPage = getState().search.next
  if (!nextPage) {
    return
  }
  return fetchJson(nextPage).then((d) => dispatch(receiveNextResults(d)))
}

function generateSeed() {
  return Math.floor(Math.random() * 100)
}
